import {colors} from "../../../shared/colors";
import React from "react";

const PriceCompareCard = ({vehicle}: any): React.JSX.Element => {

    const ListItem = ({label, value, value2}: any): React.JSX.Element => (
        <div className='flex flex-row justify-between gap-x-4'>
            <p>{label}</p>
            <p>{value}</p>
            <p>{value2}</p>
        </div>
    );

    return (
        <div className="flex flex-col">
            <div className='p-5 text-white font-semibold' style={{backgroundColor: colors.primary}}>
                <h1>{vehicle.name}</h1>
                <h1 className='border-b pb-1'>{vehicle.model}</h1>
                <div className='flex flex-row justify-between'>
                    <h1 className='text-sm'>Avis iLease</h1>
                    <div className='flex flex-col text-sm'>
                        <h1>{vehicle.priceHead}</h1>
                        <p className='text-gray-200 font-normal'>60 months</p>
                    </div>
                </div>
                <p className='font-normal' style={{fontSize: 11}}>(incl 1 500 km's pm insurance, maintenance, tyres, etc)</p>
            </div>
            <div className='flex flex-col justify-between border border-gray-300 px-4 pt-4 pb-8 text-gray-500' style={{minHeight: 580}}>
                <div className="flex flex-col gap-y-1">
                    <h1 className='font-semibold'>Compare to:</h1>
                    <p className="border-b-2 pb-1">Bank Finance</p>
                    <h1 className="font-semibold mt-2">{vehicle.name} {vehicle.model}</h1>
                    <p className="mb-3">
                        Assumed km's 1 500 per month
                        <br />
                        Price {vehicle.priceIncl}
                    </p>
                    <ListItem label='Balloon Payment' value={vehicle.balloon} value2='35%' />
                    <ListItem label='Finance period (months)' value='60' />
                    <ListItem label='Interest (Prime + 1%)' value='12.75%' />
                    <ListItem label='Monthly Payment' value={vehicle.monthly} />
                    <ListItem label='Maintenance/Tyre provision p.m' value={vehicle.maintenance} />
                    <ListItem label='Insurance p.m' value={vehicle.insurance} />
                    <ListItem label='+ roadside assistance, vehicle tracking' value='R120' />
                </div>
                <div className='flex flex-row justify-end border-y-2 py-1 mt-3'>
                    <span className='text-red-600 font-semibold'>{vehicle.total}</span>
                </div>
            </div>
        </div>
    );
};
export default PriceCompareCard;
