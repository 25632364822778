export const sortASC = (a: any, b: any) => {
    const priceA = parseInt(a.options[0].price.replaceAll(' ', '')); // Assuming that the first option is the relevant one
    const priceB = parseInt(b.options[0].price.replaceAll(' ', ''));
    return priceA - priceB;
};
export const sortDESC = (a: any, b: any) => {
    const priceA = parseInt(a.options[0].price.replaceAll(' ', '')); // Assuming that the first option is the relevant one
    const priceB = parseInt(b.options[0].price.replaceAll(' ', ''));
    return priceB - priceA;
};

export const priceRangeLabelFormat = (value: number): string => 'R ' + value;

export const filterByMake = (vehicles: any[], filter: string[]): any => {
    return filter.length > 0 
    ? vehicles.filter((vehicle: any) => filter.includes(vehicle.make))
    : vehicles;
};

// todo: object ting
export const filterByPriceRange = (vehicles: any, range: number[]): any => {
    return range[1] !== 0
    ? vehicles.filter((vehicle: any) => parseInt(vehicle.options[0].price.replaceAll(' ', '')) >= range[0] && parseInt(vehicle.options[0].price.replaceAll(' ', '')) <= range[1]).sort(sortDESC)
    : vehicles;
};