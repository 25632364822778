import React from 'react'
import { Link } from 'react-router-dom'
import { colors } from '../../shared/colors'
import { CreateUserDto } from '../../shared/dtos/create-user.dto';
import { createValidate } from './validate';
import { Success } from '../../shared/components/success';
import { createUser } from '../../lib/services/auth.service';
import { toast } from 'react-toastify';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { btnStyle, formatNumber, isError } from '../../shared/helpers/helper';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

export const CreateAccount = () => {

    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [showPassword2, setShowPassword2] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [created, setCreated] = React.useState<boolean>(false);
  
    const [form, setForm] = React.useState<CreateUserDto>({
      firstName: '',
      lastName: '',
      cellNumber: '',
      emailAddress: '',
      idNumber: '',
      password: '',
      confirmPassword: '',
    });
  
    const [validator, setValidator] = React.useState<any>({
      firstName: '',
      lastName: '',
      cellNumber: '',
      emailAddress: '',
      idNumber: '',
      password: '',
      confirmPassword: '',
    });
  
    const handleCreateUser = async () => {
      if(!isFormValid(form, validator, setValidator)) return;
      setLoading(true);
      const result = await createUser(form, window.location.origin);
      if(typeof(result) === 'boolean'){
        toast.error('User already exists!')
        setLoading(false);
        return;
      };
      if(result.length > 0) {
        setCreated(true);
        return;
      } else
      setLoading(false);
    };

    if(created){
        return(
            <Success
                title="Account created successfully."
                message="Check your emails for account verification."
                returnUrl="/auth/login"
            />
        );
    };
    
  return (
    <div className='flex flex-col mt-20 w-full text-white'>
        <div className='flex flex-row uppercase'>
            <Link
                to={'/auth/login'}
                className='text-lg mr-5'
            >Login
            </Link>
            <h1 className='text-lg underline underline-offset-8 font-semibold' style={{ color: colors.primary }}>Create Account</h1>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mt-10 md:w-1/2'>
            <TextField
                label="Full Name"
                type="text"
                variant="outlined"
                required
                value={form.firstName}
                error={isError(validator.firstName)}
                helperText={validator.firstName}
                onChange={(e) => {
                    setForm({ ...form, firstName: e.target.value });
                    setValidator({ ...validator, firstName: createValidate('firstName', e.target.value) });
                }}
                onBlur={(e) => setValidator({ ...validator, firstName: createValidate('firstName', e.target.value) })}
                fullWidth 
            />
            <TextField
                label="Last Name"
                type="text"
                variant="outlined"
                required
                value={form.lastName}
                error={isError(validator.lastName)}
                helperText={validator.lastName}
                onChange={(e) => {
                    setForm({ ...form, lastName: e.target.value });
                    setValidator({ ...validator, lastName: createValidate('lastName', e.target.value) });
                }}
                onBlur={(e) => setValidator({ ...validator, lastName: createValidate('lastName', e.target.value) })}
                fullWidth 
            />
            <TextField
                label="Cellphone Number"
                type="text"
                variant="outlined"
                required
                value={form.cellNumber}
                error={isError(validator.cellNumber)}
                helperText={validator.cellNumber}
                onChange={(e: any) => {
                    setForm({ ...form, cellNumber: formatNumber(e.target.value, e.nativeEvent.inputType) });
                    setValidator({ ...validator, cellNumber: createValidate('cellNumber', e.target.value) });
                }}
                onBlur={(e) => setValidator({ ...validator, cellNumber: createValidate('cellNumber', e.target.value, 'onBlur') })}
                fullWidth 
            />
            <TextField
                label="Email Address"
                type="text"
                variant="outlined"
                required
                value={form.emailAddress}
                error={isError(validator.emailAddress)}
                helperText={validator.emailAddress}
                onChange={(e) => {
                    setForm({ ...form, emailAddress: e.target.value });
                    setValidator({ ...validator, emailAddress: createValidate('emailAddress', e.target.value) });
                }}
                onBlur={(e) => setValidator({ ...validator, emailAddress: createValidate('emailAddress', e.target.value, 'onBlur')})}
                fullWidth 
            />
            <TextField
                label="ID Number"
                type="text"
                variant="outlined"
                className='col-span-full'
                required
                value={form.idNumber}
                error={isError(validator.idNumber)}
                helperText={validator.idNumber}
                onChange={(e) => {
                    setForm({ ...form, idNumber: formatID(e.target.value) });
                    setValidator({ ...validator, idNumber: createValidate('idNumber', e.target.value) });
                }}
                onBlur={(e) => setValidator({ ...validator, idNumber: createValidate('idNumber', e.target.value, 'onBlur') })}
                fullWidth 
            />
            <Tooltip
                title="Password should be at least 8 characters long and should have at least 1 UPPERCASE letters, 1 special character and 1 number."
                placement='top'
            >
                <TextField
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    inputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        </InputAdornment>
                    )
                    }}
                    value={form.password}
                    error={isError(validator.password)}
                    helperText={validator.password}
                    onChange={(e) => {
                    setForm({ ...form, password: e.target.value });
                    setValidator({ ...validator, password: createValidate('password', e.target.value) });
                    }}
                    onBlur={(e) => setValidator({ ...validator, password: createValidate('password', e.target.value, 'onBlur') })}
                    fullWidth 
                />
            </Tooltip>
            <TextField
                label="Confirm Password"
                type={showPassword2 ? 'text' : 'password'}
                variant="outlined"
                inputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                            onClick={() => setShowPassword2(!showPassword2)}
                            onMouseDown={() => setShowPassword2(!showPassword2)}
                            >
                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                value={form.confirmPassword}
                error={isError(validator.confirmPassword)}
                helperText={validator.confirmPassword}
                onChange={(e) => {
                    setForm({ ...form, confirmPassword: e.target.value });
                    setValidator({ ...validator, confirmPassword: createValidate('confirmPassword', e.target.value, '', form.password)});
                } }
                onBlur={(e) => setValidator({ ...validator, confirmPassword: createValidate('confirmPassword', e.target.value, 'onBlur', form.password)})}
                fullWidth 
            />
        </div>
      <div className='flex flex-col items-center md:items-start my-10 md:my-2'>
        <div className='flex flex-col w-full md:hidden'>
            <LoadingButton
                style={{ backgroundColor: loading ? 'gray' : colors.primary, color: 'white', borderRadius: 0 }}
                onClick={handleCreateUser}
                loading={loading}
                loadingPosition="start"
                disabled={loading}
                variant="contained"
            >
                <span>Create Account</span>
            </LoadingButton>
        </div>
        <div className='hidden md:block'>
            <LoadingButton
                style={{...btnStyle as any, backgroundColor: loading ? 'gray' : colors.primary, textTransform: 'uppercase' }}
                onClick={handleCreateUser}
                loading={loading}
                loadingPosition="start"
                disabled={loading}
                variant="contained"
            >
                <span>Create Account</span>
            </LoadingButton>
        </div>
        </div>
    </div>
  )
}


const isFormValid = (form: CreateUserDto, validator: any, setValidator: any): boolean => {
    const firstName = createValidate('firstName', form.firstName, 'onBlur');
    const lastName = createValidate('lastName', form.lastName, 'onBlur');
    const cellNumber = createValidate('cellNumber', form.cellNumber, 'onBlur');
    const emailAddress = createValidate('emailAddress', form.emailAddress, 'onBlur');
    const idNumber = createValidate('idNumber', form.idNumber, 'onBlur');
    const password = createValidate('password', form.password, 'onBlur');
    const confirmPassword = createValidate('confirmPassword', form.confirmPassword, 'onBlur', form.password);
  
    setValidator({
      firstName: firstName,
      lastName: lastName,
      cellNumber: cellNumber,
      emailAddress: emailAddress,
      idNumber: idNumber,
      password: password,
      confirmPassword: confirmPassword
    });
  
    if(
      !firstName &&
      !lastName &&
      !cellNumber &&
      !emailAddress &&
      !idNumber &&
      !password &&
      !confirmPassword
    )return true
    else return false;
};

const formatID = (value: string): string => {
    const numberPattern:RegExp = /[0-9]/;
    if(!numberPattern.test(value[value.length-1])) return value.substring(0, value.length - 1);
    if(value.length > 13) return value.substring(0, value.length - 1);
    return value;
};