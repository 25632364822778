import React from 'react'
import { ImagePreloader } from '../../shared/helpers/image-preloader';
import { Banner } from '../../shared/components/banner';
import { useAppDispatch } from '../../redux/store';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { setTodoQuestionnaire } from '../../redux/features/generalSlice';
import { LinksType } from './types/links.type';
import { Links } from './data/data';
import { Chip, Stack } from '@mui/material';
import { colors } from '../../shared/colors';
import { CallToAction } from '../../shared/components/call-to-action/call-to-action';

const imageUrls = [
    require('../../assets/images/about-ilease.jpg'),
  ];

export const AboutIleaseLayout = () => {

    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = React.useState<string>('');
    const [breadcrumb, setBreadcrumb] = React.useState<string>();
    const [isHoverLink, setIsHoverLink] = React.useState<string | undefined>(undefined);
    const [isPrequalifyHover, setIsPrequalifyHover] = React.useState<boolean>(false);

    const handlePrequalify = (): void => {
        dispatch(setTodoQuestionnaire(true));
        navigate('/insurance-questionnaire');
    };

    React.useEffect(() => {
        setCurrentPage(location.pathname);
        const link: LinksType = Links.filter((link: LinksType) => link.url === location.pathname)[0];
        if(link) setBreadcrumb(link.breadcrumb);
    }, [location])
    
    return (
        <ImagePreloader images={imageUrls}>
            <Banner image={require('../../assets/images/about-ilease.jpg')} text='ABOUT iLEASE' origin={'About iLease'} breadcrumb={breadcrumb} />
            <div className='flex flex-col md:flex-row mx-auto max-w-7xl px-6 lg:px-8'>
                
                {/* WEB */}
                <div className='hidden md:flex flex-col w-1/4 items-end pt-8 px-4'>
                    <div>
                        {
                            Links.map((link, index) =>
                                <div className='flex flex-row gap-x-4 items-center py-1' key={index}>
                                    <span className={`w-1 h-4 bg-red-500 rounded-full 
                                        ${currentPage === link.url ? 'visible' : 'invisible hover:visible'}
                                    `}>&nbsp;</span>
                                    <Link
                                        to={link.url}
                                        className={`hover:bg-gray-100 p-1 rounded-md w-44
                                            ${currentPage === link.url ? 'bg-gray-100 font-bold ' : ''}
                                        `}
                                        style={{color: currentPage === link.url ? colors.primary : '#808080'}}
                                    >
                                        {link.text}
                                    </Link>
                                </div>
                            )
                        }
                        <CallToAction />
                    </div>
                </div>

                {/* MOBILE */}
                <div className='p-2 block md:hidden my-5 overflow-x-scroll'>
                    <Stack direction="row" spacing={1}>
                        {
                            Links.map((link, index) =>
                                <Chip
                                    key={index}
                                    onMouseEnter={() => setIsHoverLink(link.url)}
                                    onMouseLeave={() => setIsHoverLink(undefined)}
                                    onClick={() => navigate(link.url)} 
                                    label={link.text} 
                                    className={`font-semibold`} 
                                    style={{ 
                                        backgroundColor: currentPage === link.url || isHoverLink === link.url ? colors.primary: colors.lightGray, 
                                        color: currentPage === link.url || isHoverLink === link.url ? '#fff' : '#000'
                                    }} 
                                />
                            )
                        }
                        <Chip 
                            onMouseEnter={() => setIsPrequalifyHover(true)}
                            onMouseLeave={() => setIsPrequalifyHover(false)} 
                            label={'Pre-qualify'} 
                            className={`font-semibold`}
                            style={{
                                backgroundColor: isPrequalifyHover ? colors.primary : colors.lightGray,
                                color: isPrequalifyHover ? '#fff' : '#000'
                            }}
                            onClick={() => handlePrequalify()}
                        />
                    </Stack>
                </div>
                <div className='w-full md:border-l-2 md:px-10 md:pt-10'>
                    <Outlet />
                </div>
            </div>
        </ImagePreloader>
    );
};
