import { RadioGroup } from '@headlessui/react'
import React from 'react'

export const RadioOption = ({option, }: any) => {
  return (
    <RadioGroup.Option
        value={option}
        style={{width: 300}}
        className={({ checked } : any) =>
        `${checked ? 'bg-red-700 text-white' : 'bg-white hover:bg-red-100 hover:text-white'} cursor-pointer px-5 py-1 border`}
    >
        {
            ({ checked }) =>
                <div className="flex flex-col w-full">
                    <RadioGroup.Label
                        as="p"
                        className={`font-medium ${checked ? 'text-white' : 'text-gray-500'}`}
                    >
                        R {option.price} ({option.duration})
                    </RadioGroup.Label>
                    <RadioGroup.Description
                        as="span"
                        className={`text-sm ${checked ? 'text-white' : 'text-gray-500'}`}
                    >
                        Kilometers: {option.kilometers} km
                    </RadioGroup.Description>
                </div>
        }
    </RadioGroup.Option>
  )
}
