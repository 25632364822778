export const validate = (value: string, component: string): any => {
    if(component === 'avgMonthlyKM'){
        if(!value) return 'Enter average monthly kilometers.';
        if(value === '0') return 'Average monthly kilometers must be greater than zero.';
    };
    if(component === 'prefContractPeriod'){
        if(!value) return 'Enter preferred contract period.';
        if(value === '0') return 'Preferred contract period must be greater than zero.';
    };
    if(component === 'deliveryDateAndArea'){
        if(!value) return 'Enter delivery date and area.';
    };
    if(component === 'mmCode'){
        if(!value) return 'Enter MM code.';
    };
    if(component === 'make'){
        if(!value) return 'Enter make.';
    };
    if(component === 'model'){
        if(!value) return 'Enter model.';
    };
    return null;
};