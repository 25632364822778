import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import userSlice from "./features/userSlice";
import cartSlice from "./features/cartSlice";
import storage from "redux-persist/lib/storage";
import as400Slice from "./features/as400Slice";
import generalSlice from "./features/generalSlice";

const rootReducer = combineReducers({
  user: userSlice,
  cart: cartSlice,
  as400: as400Slice,
  general: generalSlice
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: []
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    })
    // .concat(baseApi.middleware),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;