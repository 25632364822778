import React from 'react'
import { colors } from '../../../shared/colors';

export const IleaseJourneyCard = ({journey}: any): React.JSX.Element => {
    return (
        <div className='flex flex-col w-full p-5 text-white rounded-sm' style={{backgroundColor: colors.primary}}>
            <div className='flex flex-row items-center justify-between border-b pb-2'>
                <p className='font-semibold uppercase' style={{fontSize: 13}}>{journey.number}. {journey.title}</p>
                {journey.icon}
            </div>
            <p className='mt-3' style={{fontSize: 13}}>{journey.body}</p>
        </div>
    );
};
