import React from 'react'
import { colors } from '../../shared/colors';
import { Banner } from '../../shared/components/banner';
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, TextField } from '@mui/material';
import { QuestionNumber } from './components/question-number';
import { btnStyle, isError } from '../../shared/helpers/helper';
import { DatePicker } from '@mui/x-date-pickers';
import { submitInsuranceQuestionnaire } from '../../lib/services/avis-api.service';
import { LoadingButton } from '@mui/lab';
import { Outcome } from './components/outcome';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setToDoPrequalify, setTodoQuestionnaire } from '../../redux/features/generalSlice';
import { toast } from 'react-toastify';
import { ImagePreloader } from '../../shared/helpers/image-preloader';

const imageUrls = [
  require('../../assets/images/prequalify.jpg')
];

type FormType = {
    question1: boolean | undefined,
    question2: number | undefined,
    question3: Date | undefined,
    question4: number | undefined,
    question5: boolean | undefined,
};

const initForm: FormType = {
    question1: undefined,
    question2: undefined,
    question3: undefined,
    question4: undefined,
    question5: undefined
};

export const InsuranceQuestionnaire = (): JSX.Element => {

    const user = useAppSelector((state) => state.user.user);
    const dispatch = useAppDispatch();
    const [isDeclined, setIsDeclined] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [form, setForm] = React.useState<FormType>(initForm)
    const [validator, setValidator] = React.useState<any>({
        question1: undefined,
        question2: undefined,
        question3: undefined,
        question4: undefined,
        question5: undefined
    });

    const handleSubmit = () => {
        setLoading(true);
        const isValid: boolean = validateAll(form, setValidator);
        if(!isValid) {
            setLoading(false);    
            return;
        };
        const customer = {
            idNumber: user.idNumber,
            name: user.firstName + ' ' + user.lastName
        };
        const data: any = Object.assign({}, customer, form);
        data.question3 = data.question3?.toISOString().substring(0, 10);
        submitInsuranceQuestionnaire(data)
        .then((res) => {
            setLoading(false);
            if(!res){
                toast.error('Failed to submit insurance questionnaire');
                return;
            };
            if(data.question5 === true){
                dispatch(setTodoQuestionnaire(false));
                setIsDeclined(true);
            }
            else{
                dispatch(setTodoQuestionnaire(false));
                dispatch(setToDoPrequalify(true));
                const origin: string = window.location.origin;
                window.location.replace(origin + '/prequalify');
            };
        });
    };
    
    React.useEffect(() => {
      window.scrollTo(0, 0)
    }, []);

    if(isDeclined) return <Outcome />
    
    return (
        <ImagePreloader images={imageUrls}>
            <Banner image={require('../../assets/images/prequalify.jpg')} text="Insurance Questionnaire" origin="Insurance Questionnaire" />
            <div className='mx-auto max-w-7xl py-10 p-6 lg:px-8'>
                <h1 className='uppercase font-semibold mb-4' style={{color: colors.primary}}>Complete the questions</h1>
                <div className='flex flex-col divide-y mb-10'>
                    {/* Question 1 */}
                    <div className='flex flex-col md:flex-row md:items-center md:justify-between py-2'>
                        <div className='flex flex-row gap-x-5 mb-4 md:mb-0'>
                            <QuestionNumber number={1} />
                            <p>Have you had uninterrupted comprehensive vehicle insurance during the last three months.</p>
                        </div>
                        <FormControl className='flex-flex-row items-center'>
                            <RadioGroup
                                row
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel
                                    value="Yes" 
                                    control={
                                        <Radio
                                            onChange={() => {
                                                setForm({...form, question1: true})
                                                setValidator({...validator, question1: undefined})
                                            }}
                                            checked={typeof form.question1 === 'boolean' ? form.question1 : false}
                                            sx={{
                                                color: colors.primary,
                                                '&.Mui-checked': {
                                                    color: colors.primary,
                                                },
                                            }} 
                                        />
                                    } 
                                        label="Yes" 
                                    />
                                <FormControlLabel 
                                    value="No" 
                                    control={
                                        <Radio
                                        onChange={() => {
                                            setForm({...form, question1: false})
                                            setValidator({...validator, question1: undefined})
                                        }}
                                        checked={typeof form.question1 === 'boolean' ? !form.question1 : false}
                                        sx={{
                                            color: colors.primary,
                                            '&.Mui-checked': {
                                                color: colors.primary,
                                            },
                                        }} 
                                        />
                                    } 
                                    label="No" 
                                    />
                            </RadioGroup>
                            <FormHelperText style={{color: colors.primary}}>{validator.question1}</FormHelperText>
                        </FormControl>
                    </div>

                    {/* Question 2 */}
                    <div className='flex flex-col md:flex-row md:items-center md:justify-between py-2'>
                        <div className='flex flex-row gap-x-5 mb-4 md:mb-0'>
                            <QuestionNumber number={2} />
                            <p>For how long have you previously had uninterrupted comprehensive vehicle insurance.</p>
                        </div>
                        <FormControl className='flex-flex-row items-center'>
                            <TextField
                                type="number"
                                variant="outlined"
                                required
                                className='w-44'
                                placeholder='Enter Years'
                                size='small'
                                value={form.question2}
                                error={isError(validator.question2)}
                                helperText={validator.question2}
                                onChange={(e) => {
                                    setForm({...form, question2: e.target.value ? parseInt(e.target.value) : undefined}) 
                                    setValidator({...validator, question2: doValidate(2, e.target.value ? parseInt(e.target.value) : undefined)});            
                                }}
                                onBlur={(e) => setValidator({...validator, question2: doValidate(2, e.target.value ? parseInt(e.target.value) : undefined)})}
                            />
                        </FormControl>
                    </div>

                    {/* Question 3 */}
                    <div className='flex flex-col md:flex-row md:items-center md:justify-between py-2'>
                        <div className='flex flex-row gap-x-5 mb-4 md:mb-0'>
                            <QuestionNumber number={3} />
                            <p>When last did you have a vehicle accident and/or stolen vehicle.</p>
                        </div>
                        <FormControl className='flex flex-row items-center'>
                            <DatePicker
                                onChange={(date: any) => {
                                    setForm({...form, question3: new Date(date.getTime() - date.getTimezoneOffset() * 60000)})
                                }}
                                className="w-44"
                                maxDate={new Date()}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        InputLabelProps: {
                                            shrink: true,
                                        },
                                        variant: 'outlined',
                                    },
                                }}
                            />
                        </FormControl>
                    </div>

                    {/* Question 4 */}
                    <div className='flex flex-col md:flex-row md:items-center md:justify-between py-2'>
                        <div className='flex flex-row gap-x-5 mb-4 md:mb-0'>
                            <QuestionNumber number={4} />
                            <p>How many claims have you had in the last 3 years.</p>
                        </div>
                        <FormControl className='flex flex-row items-center'>
                            <TextField
                                type="number"
                                variant="outlined"
                                required
                                className='w-44'
                                placeholder='Enter no. claims'
                                size='small'
                                value={form.question4}
                                error={isError(validator.question4)}
                                helperText={validator.question4}
                                onChange={(e) => {
                                    setForm({...form, question4: e.target.value ? parseInt(e.target.value) : undefined}) 
                                    setValidator({...validator, question4: doValidate(4, e.target.value ? parseInt(e.target.value) : undefined)});            
                                }}
                                onBlur={(e) => setValidator({...validator, question4: doValidate(4, e.target.value ? parseInt(e.target.value) : undefined)})}
                            />
                        </FormControl>
                    </div>

                    {/* Question 5 */}
                    <div className='flex flex-col md:flex-row md:items-center md:justify-between py-2'>
                        <div className='flex flex-row gap-x-5 mb-4 md:mb-0'>
                            <QuestionNumber number={5} />
                            <p>Have you ever had a vehicle insurance policy cancelled and/or endorsed with special conditions.</p>
                        </div>
                        <FormControl className='flex-flex-row items-center'>
                            <RadioGroup row>
                                <FormControlLabel
                                    value="Yes" 
                                    control={
                                        <Radio
                                            onChange={() => {
                                                setForm({...form, question5: true})
                                                setValidator({...validator, question5: undefined})
                                            }}
                                            checked={typeof form.question5 === 'boolean' ? form.question5 : false}
                                            sx={{
                                                color: colors.primary,
                                                '&.Mui-checked': {
                                                color: colors.primary,
                                                },
                                            }} 
                                        />
                                    } 
                                    label="Yes" 
                                />
                                <FormControlLabel 
                                    value="No" 
                                    control={
                                        <Radio
                                            onChange={() => {
                                                setForm({...form, question5: false})
                                                setValidator({...validator, question5: undefined})
                                            }}
                                            checked={typeof form.question5 === 'boolean' ? !form.question5 : false}
                                            sx={{
                                                color: colors.primary,
                                                '&.Mui-checked': {
                                                color: colors.primary,
                                                },
                                            }} 
                                        />
                                    } 
                                    label="No" 
                                />
                            </RadioGroup>
                            <FormHelperText style={{color: colors.primary}}>{validator.question5}</FormHelperText>
                        </FormControl>
                    </div>
                </div>

                <div className='flex flex-row justify-start'>
                    <LoadingButton
                        className='w-44'
                        style={{...btnStyle as any, backgroundColor: loading ? 'gray' : colors.primary}}
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<></>}
                        disabled={loading}
                        variant="contained"
                        >
                        <span>Submit</span>
                    </LoadingButton>
                </div>
            </div>
        </ImagePreloader>
    );
};

const validateAll = (form: FormType, setValidator: any): boolean => {
    const q1 = doValidate(1, form.question1);
    const q2 = doValidate(2, form.question2);
    const q4 = doValidate(4, form.question4);
    const q5 = doValidate(5, form.question5);

    setValidator({
        question1: q1,
        question2: q2,
        question4: q4,
        question5: q5
    });

    if(!q1 && !q2 && !q4 && !q5) return true
    else return false
};

const doValidate = (question: number, value: undefined | boolean | number) => {
    if(question === 1 || question === 5){
        if(value === undefined) return 'Select answer';
    };
    if(question === 2 ){
        if(value === undefined) return 'Enter number of years';
    };
    if(question === 4 ){
        if(value === undefined) return 'Enter number of claims';
    };
    return undefined;
};
