export const HomeSVG = (): React.JSX.Element => (
  <div className="w-fit p-1">
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 25 19">
      <path id="footer-home" d="M12.168,4.929l-8,6.441v6.951a.671.671,0,0,0,.2.48.7.7,0,0,0,.491.2l4.863-.012a.7.7,0,0,0,.489-.2.67.67,0,0,0,.2-.479V14.25a.671.671,0,0,1,.2-.48.7.7,0,0,1,.491-.2h2.778a.7.7,0,0,1,.491.2.671.671,0,0,1,.2.48v4.057a.666.666,0,0,0,.052.26.678.678,0,0,0,.15.221.7.7,0,0,0,.226.148.709.709,0,0,0,.266.052L20.138,19a.7.7,0,0,0,.491-.2.671.671,0,0,0,.2-.48V11.365l-8-6.436a.539.539,0,0,0-.664,0ZM24.808,9.307,21.18,6.384V.509a.5.5,0,0,0-.153-.36A.527.527,0,0,0,20.659,0h-2.43a.527.527,0,0,0-.368.149.5.5,0,0,0-.153.36v3.08L13.822.464a2.121,2.121,0,0,0-2.647,0L.189,9.307a.511.511,0,0,0-.128.154.5.5,0,0,0,.059.563l1.107,1.315a.519.519,0,0,0,.157.126.532.532,0,0,0,.4.037.524.524,0,0,0,.179-.094L12.168,3.19a.539.539,0,0,1,.664,0l10.209,8.218a.524.524,0,0,0,.179.094.532.532,0,0,0,.4-.037.52.52,0,0,0,.158-.125l1.107-1.315a.506.506,0,0,0,.1-.176.5.5,0,0,0-.04-.389A.512.512,0,0,0,24.808,9.307Z" fill="#fff"/>
    </svg>
  </div>
);

export const SearchSVG = (): React.JSX.Element => (
  <div className="w-fit p-1">
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 22 22">
      <path id="footer-search" d="M21.7,19.02l-4.284-4.284a1.031,1.031,0,0,0-.73-.3h-.7a8.934,8.934,0,1,0-1.547,1.547v.7a1.03,1.03,0,0,0,.3.73L19.024,21.7a1.027,1.027,0,0,0,1.457,0L21.7,20.481A1.036,1.036,0,0,0,21.7,19.02ZM8.938,14.436a5.5,5.5,0,1,1,5.5-5.5A5.5,5.5,0,0,1,8.938,14.436Z" fill="#fff"/>
    </svg>
  </div>
);

export const FilledQuestionSVG = (): React.JSX.Element => (
  <div className="w-fit p-1">
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24.219 24.219">
      <path id="footer-question" d="M24.219,12.109A12.109,12.109,0,1,1,12.109,0,12.109,12.109,0,0,1,24.219,12.109ZM12.434,4A6.324,6.324,0,0,0,6.743,7.117a.587.587,0,0,0,.133.794L8.57,9.2a.586.586,0,0,0,.814-.1c.872-1.106,1.47-1.748,2.8-1.748,1,0,2.231.642,2.231,1.609,0,.731-.6,1.107-1.589,1.659-1.149.644-2.669,1.445-2.669,3.45v.2a.586.586,0,0,0,.586.586h2.734a.586.586,0,0,0,.586-.586v-.065c0-1.39,4.062-1.448,4.062-5.208C18.124,6.152,15.187,4,12.434,4Zm-.325,12.109a2.246,2.246,0,1,0,2.246,2.246A2.249,2.249,0,0,0,12.109,16.113Z" fill="#fff"/>
    </svg>
  </div>
);

export const FilledMailSVG = (): React.JSX.Element => (
  <div className="w-fit p-1">
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 25 18.75">
      <path id="footer-envelope" d="M24.526,6.191A.294.294,0,0,1,25,6.421v9.985a2.344,2.344,0,0,1-2.344,2.344H2.344A2.344,2.344,0,0,1,0,16.406V6.426A.292.292,0,0,1,.474,6.2C1.567,7.046,3.018,8.125,8,11.743c1.03.752,2.769,2.334,4.5,2.324,1.743.015,3.516-1.6,4.507-2.324C21.987,8.125,23.433,7.041,24.526,6.191ZM12.5,12.5c1.133.019,2.764-1.426,3.584-2.021,6.479-4.7,6.973-5.112,8.467-6.284A1.168,1.168,0,0,0,25,3.271V2.344A2.344,2.344,0,0,0,22.656,0H2.344A2.344,2.344,0,0,0,0,2.344v.928a1.176,1.176,0,0,0,.449.923c1.494,1.167,1.987,1.582,8.467,6.284C9.736,11.074,11.367,12.519,12.5,12.5Z" fill="#fff"/>
    </svg>
  </div>
);

export const InfoSVG = (): React.JSX.Element => (
  <div className="w-fit p-1">
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 9.375 25">
      <path id="footer-i" d="M.977,20.714h.977V13.661H.977A.977.977,0,0,1,0,12.684V10.352a.977.977,0,0,1,.977-.977H6.445a.977.977,0,0,1,.977.977V20.714H8.4a.977.977,0,0,1,.977.977v2.332A.977.977,0,0,1,8.4,25H.977A.977.977,0,0,1,0,24.023V21.691A.977.977,0,0,1,.977,20.714ZM4.688,0A3.516,3.516,0,1,0,8.2,3.516,3.516,3.516,0,0,0,4.688,0Z" fill="#fff"/>
    </svg>
  </div>
);

export const DocumentSVG = (): React.JSX.Element => (
  <div className="w-fit p-1">
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 18.75 25">
      <path id="footer-file" d="M10.938,6.641V0H1.172A1.169,1.169,0,0,0,0,1.172V23.828A1.169,1.169,0,0,0,1.172,25H17.578a1.169,1.169,0,0,0,1.172-1.172V7.813H12.109A1.175,1.175,0,0,1,10.938,6.641Zm3.125,11.523a.588.588,0,0,1-.586.586h-8.2a.588.588,0,0,1-.586-.586v-.391a.588.588,0,0,1,.586-.586h8.2a.588.588,0,0,1,.586.586Zm0-3.125a.588.588,0,0,1-.586.586h-8.2a.588.588,0,0,1-.586-.586v-.391a.588.588,0,0,1,.586-.586h8.2a.588.588,0,0,1,.586.586Zm0-3.516v.391a.588.588,0,0,1-.586.586h-8.2a.588.588,0,0,1-.586-.586v-.391a.588.588,0,0,1,.586-.586h8.2A.588.588,0,0,1,14.063,11.523ZM18.75,5.952v.3H12.5V0h.3a1.171,1.171,0,0,1,.83.342l4.78,4.785A1.168,1.168,0,0,1,18.75,5.952Z" fill="#fff"/>
    </svg>
  </div>
);

export const FacebookSVG = (): React.JSX.Element => (
  <div className="w-fit p-1">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.39 25">
      <path id="footer-facebook" d="M12.512,14.063l.694-4.524H8.865V6.6a2.262,2.262,0,0,1,2.551-2.444H13.39V.306A24.068,24.068,0,0,0,9.886,0C6.311,0,3.974,2.167,3.974,6.09V9.538H0v4.524H3.974V25H8.865V14.063Z" fill="#fff"/>
    </svg>
  </div>
);

export const TwitterSVG = (): React.JSX.Element => (
  <div className="w-fit p-1">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 20.305">
      <path id="footer-twiiter" d="M22.43,5.06c.016.222.016.444.016.666A14.478,14.478,0,0,1,7.868,20.3,14.479,14.479,0,0,1,0,18a10.59,10.59,0,0,0,1.237.063A10.261,10.261,0,0,0,7.6,15.879a5.133,5.133,0,0,1-4.791-3.553,6.469,6.469,0,0,0,.968.079,5.418,5.418,0,0,0,1.348-.175A5.124,5.124,0,0,1,1.015,7.2V7.138a5.16,5.16,0,0,0,2.316.65A5.131,5.131,0,0,1,1.745.936,14.564,14.564,0,0,0,12.31,6.3a5.784,5.784,0,0,1-.127-1.174A5.129,5.129,0,0,1,21.05,1.618,10.088,10.088,0,0,0,24.3.381,5.11,5.11,0,0,1,22.049,3.2,10.272,10.272,0,0,0,25,2.411,11.014,11.014,0,0,1,22.43,5.06Z" fill="#fff"/>
    </svg>
  </div>
);

export const InstagramSVG = (): React.JSX.Element => (
  <div className="w-fit p-1">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.887 21.882">
      <path id="footer-inst" d="M10.946,5.331a5.61,5.61,0,1,0,5.61,5.61A5.6,5.6,0,0,0,10.946,5.331Zm0,9.258a3.647,3.647,0,1,1,3.648-3.647A3.654,3.654,0,0,1,10.946,14.589ZM18.094,5.1a1.309,1.309,0,1,1-1.309-1.309A1.306,1.306,0,0,1,18.094,5.1ZM21.81,6.429a6.476,6.476,0,0,0-1.768-4.585A6.518,6.518,0,0,0,15.458.077c-1.807-.1-7.222-.1-9.028,0A6.509,6.509,0,0,0,1.844,1.84,6.5,6.5,0,0,0,.077,6.425c-.1,1.807-.1,7.222,0,9.028a6.476,6.476,0,0,0,1.768,4.585,6.527,6.527,0,0,0,4.585,1.768c1.807.1,7.222.1,9.028,0a6.476,6.476,0,0,0,4.585-1.768,6.518,6.518,0,0,0,1.768-4.585C21.913,13.646,21.913,8.236,21.81,6.429ZM19.476,17.391a3.692,3.692,0,0,1-2.08,2.08c-1.44.571-4.858.44-6.45.44s-5.015.127-6.45-.44a3.692,3.692,0,0,1-2.08-2.08c-.571-1.44-.439-4.858-.439-6.45s-.127-5.015.439-6.45A3.693,3.693,0,0,1,4.5,2.411c1.44-.571,4.858-.439,6.45-.439s5.015-.127,6.45.439a3.692,3.692,0,0,1,2.08,2.08c.571,1.44.44,4.858.44,6.45S20.048,15.956,19.476,17.391Z" fill="#fff"/>
    </svg>
  </div>
);

export const LinkedinSVG = (): React.JSX.Element => (
  <div className="w-fit p-1">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.875 21.875">
      <path id="footer-linkedin" d="M4.9,21.875H.361V7.27H4.9Zm-2.27-16.6A2.644,2.644,0,1,1,5.253,2.626,2.649,2.649,0,0,1,2.626,5.278Zm19.244,16.6H17.345V14.765c0-1.694-.034-3.867-2.358-3.867-2.358,0-2.719,1.841-2.719,3.745v7.231H7.737V7.27h4.35V9.262h.064A4.765,4.765,0,0,1,16.441,6.9c4.59,0,5.434,3.022,5.434,6.948v8.022Z" fill="#fff"/>
    </svg>
  </div>
);

export const FacebookBorderedSVG = (): React.JSX.Element => (
  <div className="w-fit p-1 border">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.39 25">
      <path id="footer-facebook" d="M12.512,14.063l.694-4.524H8.865V6.6a2.262,2.262,0,0,1,2.551-2.444H13.39V.306A24.068,24.068,0,0,0,9.886,0C6.311,0,3.974,2.167,3.974,6.09V9.538H0v4.524H3.974V25H8.865V14.063Z" fill="#fff"/>
    </svg>
  </div>
);

export const TwitterBorderedSVG = (): React.JSX.Element => (
  <div className="w-fit p-1 border">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 20.305">
      <path id="footer-twiiter" d="M22.43,5.06c.016.222.016.444.016.666A14.478,14.478,0,0,1,7.868,20.3,14.479,14.479,0,0,1,0,18a10.59,10.59,0,0,0,1.237.063A10.261,10.261,0,0,0,7.6,15.879a5.133,5.133,0,0,1-4.791-3.553,6.469,6.469,0,0,0,.968.079,5.418,5.418,0,0,0,1.348-.175A5.124,5.124,0,0,1,1.015,7.2V7.138a5.16,5.16,0,0,0,2.316.65A5.131,5.131,0,0,1,1.745.936,14.564,14.564,0,0,0,12.31,6.3a5.784,5.784,0,0,1-.127-1.174A5.129,5.129,0,0,1,21.05,1.618,10.088,10.088,0,0,0,24.3.381,5.11,5.11,0,0,1,22.049,3.2,10.272,10.272,0,0,0,25,2.411,11.014,11.014,0,0,1,22.43,5.06Z" fill="#fff"/>
    </svg>
  </div>
);

export const InstagramBorderedSVG = (): React.JSX.Element => (
  <div className="w-fit p-1 border">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.887 21.882">
      <path id="footer-inst" d="M10.946,5.331a5.61,5.61,0,1,0,5.61,5.61A5.6,5.6,0,0,0,10.946,5.331Zm0,9.258a3.647,3.647,0,1,1,3.648-3.647A3.654,3.654,0,0,1,10.946,14.589ZM18.094,5.1a1.309,1.309,0,1,1-1.309-1.309A1.306,1.306,0,0,1,18.094,5.1ZM21.81,6.429a6.476,6.476,0,0,0-1.768-4.585A6.518,6.518,0,0,0,15.458.077c-1.807-.1-7.222-.1-9.028,0A6.509,6.509,0,0,0,1.844,1.84,6.5,6.5,0,0,0,.077,6.425c-.1,1.807-.1,7.222,0,9.028a6.476,6.476,0,0,0,1.768,4.585,6.527,6.527,0,0,0,4.585,1.768c1.807.1,7.222.1,9.028,0a6.476,6.476,0,0,0,4.585-1.768,6.518,6.518,0,0,0,1.768-4.585C21.913,13.646,21.913,8.236,21.81,6.429ZM19.476,17.391a3.692,3.692,0,0,1-2.08,2.08c-1.44.571-4.858.44-6.45.44s-5.015.127-6.45-.44a3.692,3.692,0,0,1-2.08-2.08c-.571-1.44-.439-4.858-.439-6.45s-.127-5.015.439-6.45A3.693,3.693,0,0,1,4.5,2.411c1.44-.571,4.858-.439,6.45-.439s5.015-.127,6.45.439a3.692,3.692,0,0,1,2.08,2.08c.571,1.44.44,4.858.44,6.45S20.048,15.956,19.476,17.391Z" fill="#fff"/>
    </svg>
  </div>
);

export const LinkedinBorderedSVG = (): React.JSX.Element => (
  <div className="w-fit p-1 border">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.875 21.875">
      <path id="footer-linkedin" d="M4.9,21.875H.361V7.27H4.9Zm-2.27-16.6A2.644,2.644,0,1,1,5.253,2.626,2.649,2.649,0,0,1,2.626,5.278Zm19.244,16.6H17.345V14.765c0-1.694-.034-3.867-2.358-3.867-2.358,0-2.719,1.841-2.719,3.745v7.231H7.737V7.27h4.35V9.262h.064A4.765,4.765,0,0,1,16.441,6.9c4.59,0,5.434,3.022,5.434,6.948v8.022Z" fill="#fff"/>
    </svg>
  </div>
);

export const Journey1SVG = (): React.JSX.Element => (
  <div className="w-fit">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 36 36">
      <path id="about-ilease-icon-1" d="M34.77,28.82l-6.081-6.09-4.678-1.935a13.206,13.206,0,1,0-3.19,3.211l1.927,4.672,6.081,6.09a4.2,4.2,0,0,0,5.94-5.948ZM2.4,13.219A10.8,10.8,0,1,1,13.2,24.034,10.82,10.82,0,0,1,2.4,13.219Zm30.673,19.85a1.8,1.8,0,0,1-2.546,0l-5.743-5.751-1.792-4.344,4.338,1.795,5.743,5.751a1.806,1.806,0,0,1,0,2.549Z" fill="#fff"/>
    </svg>
  </div>
);

export const Journey2SVG = (): React.JSX.Element => (
  <div className="w-fit">
    <svg id="about-ilease-icon-2" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 40 40">
      <path id="Path_1" data-name="Path 1" d="M34.142,5.858A20,20,0,1,0,40,20,20,20,0,0,0,34.142,5.858ZM20,37.319A17.319,17.319,0,1,1,37.319,20,17.339,17.339,0,0,1,20,37.319Z" fill="#fff"/>
      <path id="Path_2" data-name="Path 2" d="M16.986,24.193l-6.15-6.15-1.9,1.9,8.045,8.045L31.017,13.953l-1.9-1.9Z" fill="#fff"/>
    </svg>
  </div>
);

export const Journey3SVG = (): React.JSX.Element => (
  <div className="w-fit">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 39 23">
      <path id="about-ilease-icon-3" d="M37.988,7.667c-3.158.21-7.663,3.276-9.942,4.282a12.779,12.779,0,0,1-4.639,1.461c-1.378,0-1.585-.97-1.3-3.109.067-.479.713-4.744-2.6-4.558-1.53.09-3.92,1.485-10.333,7.547L11.7,7.08A5.237,5.237,0,0,0,3.8.923L.449,3.133a.953.953,0,0,0-.3,1.324L1.2,6.074a.98.98,0,0,0,1.347.293l3.536-2.33A1.352,1.352,0,0,1,8.075,5.661L2.089,20.371A1.916,1.916,0,0,0,3.9,23a1.946,1.946,0,0,0,1.378-.563c2.572-2.528,9.43-9.026,12.875-11.727-.134,1.707-.128,3.528,1.256,5.019A5.193,5.193,0,0,0,23.4,17.244a15.62,15.62,0,0,0,6.236-1.8c2.012-.886,6.035-3.749,8.437-3.941A.969.969,0,0,0,39,10.56V8.637A.959.959,0,0,0,37.988,7.667Z" fill="#fff"/>
    </svg>
  </div>
);

export const Journey4SVG = (): React.JSX.Element => (
  <div className="w-fit">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 40.023 30">
      <path id="about-ilease-icon-4" d="M8.3,6.383h0A8.841,8.841,0,0,1,16.535,1h8.93A8.838,8.838,0,0,1,33.7,6.383h0l.914,2.2h3.542a1.82,1.82,0,0,1,1.787,2.26h0l-.422,1.624Zm0,0-.914,2.2m.914-2.2-.914,2.2m0,0H3.844a1.82,1.82,0,0,0-1.786,2.261h0l.421,1.623h0a1.82,1.82,0,0,0,1.212,1.274,5.1,5.1,0,0,0-.567,2.34v3.25a5.17,5.17,0,0,0,1.125,3.2v3.3A3.209,3.209,0,0,0,7.5,29H9.75A3.209,3.209,0,0,0,13,25.833V24.667H29v1.167A3.209,3.209,0,0,0,32.25,29H34.5a3.209,3.209,0,0,0,3.25-3.167v-3.3a5.168,5.168,0,0,0,1.125-3.2v-3.25a5.1,5.1,0,0,0-.567-2.34,1.82,1.82,0,0,0,1.213-1.274Zm6.939.176h0a2.377,2.377,0,0,1,2.21-1.426h8.93a2.377,2.377,0,0,1,2.21,1.426l.827,1.99h-15ZM9.75,18.32A1.313,1.313,0,0,1,8.811,18a1.3,1.3,0,0,1,0-1.689A1.313,1.313,0,0,1,9.75,16a1.469,1.469,0,0,1,.626.206,4.072,4.072,0,0,1,.875.649,3.917,3.917,0,0,1,.671.832,1.447,1.447,0,0,1,.2.5,1.471,1.471,0,0,1-.336.086,8.456,8.456,0,0,1-1.424.049C10.165,18.323,9.961,18.32,9.75,18.32Zm2.428-.158h0Zm20.072.158c-.211,0-.415,0-.612.007a8.456,8.456,0,0,1-1.424-.049,1.471,1.471,0,0,1-.336-.086,1.448,1.448,0,0,1,.2-.5,3.913,3.913,0,0,1,.671-.832,4.07,4.07,0,0,1,.875-.649A1.469,1.469,0,0,1,32.25,16a1.313,1.313,0,0,1,.939.315,1.3,1.3,0,0,1,0,1.689A1.313,1.313,0,0,1,32.25,18.32Z" transform="translate(-0.988)" fill="none" stroke="#fff" stroke-width="2"/>
    </svg>
  </div>
);

export const TickSVG = (): React.JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="300" height="300">
    <linearGradient id="IMoH7gpu5un5Dx2vID39Ra" x1="9.858" x2="38.142" y1="9.858" y2="38.142" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#9dffce"/>
        <stop offset="1" stop-color="#50d18d"/>
    </linearGradient>
    <path fill="url(#IMoH7gpu5un5Dx2vID39Ra)" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"/>
    <linearGradient id="IMoH7gpu5un5Dx2vID39Rb" x1="13" x2="36" y1="24.793" y2="24.793" gradientUnits="userSpaceOnUse">
        <stop offset=".824" stop-color="#135d36"/>
        <stop offset=".931" stop-color="#125933"/>
        <stop offset="1" stop-color="#11522f"/>
    </linearGradient>
    <path fill="url(#IMoH7gpu5un5Dx2vID39Rb)" d="M21.293,32.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414	c0.391-0.391,1.024-0.391,1.414,0L22,27.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414	c0.391,0.391,0.391,1.024,0,1.414l-13,13C22.317,33.098,21.683,33.098,21.293,32.707z"/>
  </svg>
);