import React from 'react';
import {colors} from "../../../shared/colors";
import { bgImage } from '../../../shared/helpers/helper';
import { SquareBullet } from '../components/square-bullet';
import { ImagePreloader } from '../../../shared/helpers/image-preloader';

const imageUrls = [
    require('../../../assets/images/about-ilease-what-you-get-6.jpg')
];

export const WhatYouGet = (): React.JSX.Element => {
  
    React.useEffect(() => {
      window.scrollTo(0, 0);
    });
    
    return (
        <ImagePreloader images={imageUrls}>
            <h1 className='text-red-600 font-bold uppercase'>What you get</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-6 mt-8">
                <div className='flex flex-row gap-x-4'>
                    <div className='flex flex-row items-center justify-center h-12 w-12 p-2' style={{backgroundColor: colors.primary}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 39.164 29.373">
                            <path id="solid_car" data-name="solid car" d="M38.248,72.567h-4.58L32.4,69.385A8.524,8.524,0,0,0,24.442,64H14.728a8.526,8.526,0,0,0-7.954,5.385L5.5,72.567H.922a.918.918,0,0,0-.89,1.14l.459,1.836a.917.917,0,0,0,.89.7H2.916A4.861,4.861,0,0,0,1.227,79.91v3.672a4.856,4.856,0,0,0,1.224,3.207v4.136A2.448,2.448,0,0,0,4.9,93.373H7.347a2.448,2.448,0,0,0,2.448-2.448V88.477H29.376v2.448a2.448,2.448,0,0,0,2.448,2.448h2.448a2.448,2.448,0,0,0,2.448-2.448V86.789a4.853,4.853,0,0,0,1.224-3.207V79.91a4.863,4.863,0,0,0-1.688-3.672H37.79a.917.917,0,0,0,.89-.7l.459-1.836a.918.918,0,0,0-.891-1.14ZM11.319,71.2A3.672,3.672,0,0,1,14.728,68.9h9.714A3.672,3.672,0,0,1,27.852,71.2l1.524,3.812H9.794ZM7.347,83.566a2.44,2.44,0,1,1,0-4.88c1.469,0,3.672,2.2,3.672,3.66S8.815,83.566,7.347,83.566Zm24.477,0c-1.469,0-3.672.244-3.672-1.22s2.2-3.66,3.672-3.66a2.44,2.44,0,1,1,0,4.88Z" transform="translate(-0.004 -64)" fill="#fff"/>
                        </svg>
                    </div>
                    <label className=' text-gray-500'>A new or nearly-new vehicle of your choice leased to you.</label>
                </div>
                <div className='flex flex-row gap-x-4'>
                    <div className='flex flex-row items-center justify-center h-12 w-12 p-2' style={{backgroundColor: colors.primary}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 43 34">
                            <path id="about-ilease-what-you-get-icon-2" d="M9.625,14.664,8.79,17.743a6.191,6.191,0,0,0-.162,2.284L6.264,21.951a.607.607,0,0,1-.982-.582l1.037-5.092a.6.6,0,0,0-.537-.717l-5.231-.5a.6.6,0,0,1-.278-1.1l4.377-2.875a.6.6,0,0,0,.133-.882L1.442,6.191a.6.6,0,0,1,.59-.971L7.184,6.245a.607.607,0,0,0,.726-.531L8.417.544A.61.61,0,0,1,9.529.269l2.909,4.326a.613.613,0,0,0,.893.131l4.058-3.3a.607.607,0,0,1,.982.582L17.61,5.737c-.182.2-.365.4-.531.624l-2.16,2.853-.72.951A6.444,6.444,0,0,0,9.625,14.664Zm33.229,8.8-.834,3.079a4.152,4.152,0,0,1-1.179,1.95,4.485,4.485,0,0,1-.589.465l-.94,3.468a2.109,2.109,0,0,1-.38.756,2.165,2.165,0,0,1-2.254.747l-2.077-.55a2.123,2.123,0,0,1-1.521-2.6l.556-2.053-16.614-4.4-.556,2.053a2.153,2.153,0,0,1-2.633,1.5l-2.077-.55a2.134,2.134,0,0,1-1.573-1.76,2.1,2.1,0,0,1,.053-.842l.94-3.468a4.4,4.4,0,0,1-.275-.694,4.114,4.114,0,0,1-.035-2.271l.835-3.079a4.235,4.235,0,0,1,2.478-2.8,4.362,4.362,0,0,1,1.242-.313l1.218-1.609L18.8,7.634a7.516,7.516,0,0,1,5.726-2.98,7.672,7.672,0,0,1,2.246.252l8.242,2.183a7.619,7.619,0,0,1,2.073.892,7.39,7.39,0,0,1,3.451,5.411l.427,3.539.241,2a4.289,4.289,0,0,1,1.528,1.988A4.148,4.148,0,0,1,42.854,23.463Zm-25.681-7.9a2.03,2.03,0,0,0-2.632,1.5,2,2,0,0,0,1.522,2.6c1.246.33,3.06,1.03,3.392-.2S18.418,15.892,17.172,15.562ZM36.7,17.433l-.427-3.539a3.154,3.154,0,0,0-.257-.92A3.216,3.216,0,0,0,33.9,11.193L25.66,9.01a3.262,3.262,0,0,0-1.89.067,3.224,3.224,0,0,0-1.527,1.1l-2.16,2.853,11.556,3.06Zm1.242,3.629c-1.246-.33-3.615,1.017-3.947,2.244s1.592,1.518,2.838,1.848a2.03,2.03,0,0,0,2.632-1.5A2,2,0,0,0,37.939,21.062Z" fill="#fff"/>
                        </svg>
                    </div>
                    <label className=' text-gray-500'>Comprehensive insurance.</label>
                </div>
                <div className='flex flex-row gap-x-4'>
                    <div className='flex flex-row items-center justify-center h-12 w-12 p-2' style={{backgroundColor: colors.primary}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 35.896 38.285">
                            <path id="solid_shield-alt" data-name="solid shield-alt" d="M49.69,6.282,35.332.3a3.6,3.6,0,0,0-2.76,0L18.214,6.282A3.586,3.586,0,0,0,16,9.6c0,14.845,8.563,25.1,16.565,28.44a3.6,3.6,0,0,0,2.76,0C41.733,35.366,51.9,26.145,51.9,9.6A3.59,3.59,0,0,0,49.69,6.282ZM33.955,33.4,33.948,4.906,47.1,10.388C46.856,21.71,40.963,29.914,33.955,33.4Z" transform="translate(-16 -0.025)" fill="#fff"/>
                        </svg>
                    </div>
                    <label className=' text-gray-500'>Normal maintenance and tyre replacement (normal wear) included and billed to Avis directly.</label>
                </div>
                <div className='flex flex-row gap-x-4'>
                    <div className='flex flex-row items-center justify-center h-12 w-12 p-2' style={{backgroundColor: colors.primary}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 31 24">
                            <path id="about-ilease-what-you-get-icon-4" d="M10.529,23.529.454,13.069a1.653,1.653,0,0,1,0-2.276L2.646,8.517a1.51,1.51,0,0,1,2.192,0l6.787,7.046L26.162.471a1.51,1.51,0,0,1,2.192,0l2.192,2.276a1.653,1.653,0,0,1,0,2.276L12.721,23.529A1.51,1.51,0,0,1,10.529,23.529Z" fill="#fff"/>
                        </svg>
                    </div>
                    <label className=' text-gray-500'>Flexible individualised usage contract.</label>
                </div>
                <div className='flex flex-row gap-x-4'>
                    <div className='flex flex-row items-center justify-center h-12 w-12 p-2' style={{backgroundColor: colors.primary}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 38.423 29.885">
                            <path id="solid_address-card" data-name="solid address-card" d="M35.221,32H3.2A3.2,3.2,0,0,0,0,35.2V58.683a3.2,3.2,0,0,0,3.2,3.2H35.221a3.2,3.2,0,0,0,3.2-3.2V35.2A3.2,3.2,0,0,0,35.221,32ZM11.74,38.4a4.269,4.269,0,1,1-4.269,4.269A4.273,4.273,0,0,1,11.74,38.4Zm7.471,15.8a1.4,1.4,0,0,1-1.494,1.281H5.763A1.4,1.4,0,0,1,4.269,54.2V52.919c0-2.121,2.008-3.842,4.483-3.842h.334a6.871,6.871,0,0,0,5.31,0h.334c2.475,0,4.483,1.721,4.483,3.842Zm14.942-3.522a.535.535,0,0,1-.534.534H24.014a.535.535,0,0,1-.534-.534V49.611a.535.535,0,0,1,.534-.534H33.62a.535.535,0,0,1,.534.534Zm0-4.269a.535.535,0,0,1-.534.534H24.014a.535.535,0,0,1-.534-.534V45.341a.535.535,0,0,1,.534-.534H33.62a.535.535,0,0,1,.534.534Zm0-4.269a.535.535,0,0,1-.534.534H24.014a.535.535,0,0,1-.534-.534V41.072a.535.535,0,0,1,.534-.534H33.62a.535.535,0,0,1,.534.534Z" transform="translate(0 -32)" fill="#fff"/>
                        </svg>
                    </div>
                    <label className=' text-gray-500'>Annual vehicle licence renewal (we send it to you).</label>
                </div>
                <div className='flex flex-row gap-x-4'>
                    <div className='flex flex-row items-center justify-center h-12 w-12 p-2' style={{backgroundColor: colors.primary}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 40.769 27.179">
                            <path id="solid_road" data-name="solid road" d="M40.565,87.97,30.671,65.321A2.155,2.155,0,0,0,28.709,64H21.8l.173,1.639a.567.567,0,0,1-.563.626H19.348a.567.567,0,0,1-.563-.626L18.958,64H12.051a2.155,2.155,0,0,0-1.963,1.321L.194,87.97a2.26,2.26,0,0,0,1.963,3.208H16.089l.73-6.914a1.132,1.132,0,0,1,1.126-1.014h4.87a1.132,1.132,0,0,1,1.126,1.014l.73,6.914H38.6a2.26,2.26,0,0,0,1.963-3.208ZM18.426,69.037a.566.566,0,0,1,.563-.507h2.781a.567.567,0,0,1,.563.507l.326,3.085a.849.849,0,0,1-.844.939H18.946a.849.849,0,0,1-.844-.939Zm3.91,11.95H18.423A1.132,1.132,0,0,1,17.3,79.735l.359-3.4a1.132,1.132,0,0,1,1.126-1.014h3.2A1.132,1.132,0,0,1,23.1,76.338l.359,3.4A1.132,1.132,0,0,1,22.336,80.987Z" transform="translate(0.005 -64)" fill="#fff"/>
                        </svg>
                    </div>
                    <label className=' text-gray-500'>Roadside assistance.</label>
                </div>
                <div className='flex flex-row gap-x-4'>
                    <div className='flex flex-row items-center justify-center h-12 w-12 p-2' style={{backgroundColor: colors.primary}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 37.275 37.275">
                            <path id="solid_satellite-dish" data-name="solid satellite-dish" d="M13.745,25.164l2-2a2.13,2.13,0,0,0,.568.117,2.33,2.33,0,1,0-2.33-2.33,2.13,2.13,0,0,0,.117.568l-2,2-8.5-8.5a1.155,1.155,0,0,0-1.82.218A14.949,14.949,0,0,0,22.016,35.474a1.147,1.147,0,0,0,.218-1.82ZM15.215-.022a1.172,1.172,0,0,0-1.238,1.165v2.3A1.153,1.153,0,0,0,15.07,4.6,18.7,18.7,0,0,1,32.611,22.186a1.163,1.163,0,0,0,1.158,1.092h2.337a1.172,1.172,0,0,0,1.165-1.238A23.356,23.356,0,0,0,15.215-.022Zm.022,6.99a1.175,1.175,0,0,0-1.26,1.172v2.337a1.161,1.161,0,0,0,1.078,1.158A11.63,11.63,0,0,1,25.606,22.207a1.17,1.17,0,0,0,1.158,1.07h2.345a1.179,1.179,0,0,0,1.172-1.26A16.383,16.383,0,0,0,15.237,6.968Z" transform="translate(0.003 0.024)" fill="#fff"/>
                        </svg>
                    </div>
                    <label className=' text-gray-500'>Telematics device.</label>
                </div>
                <div className='flex flex-row gap-x-4'>
                    <div className='flex flex-row items-center justify-center h-12 w-12 p-2' style={{backgroundColor: colors.primary}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 39.569 37.241">
                            <path id="solid_hand-holding-usd" data-name="solid hand-holding-usd" d="M18.737,10.5l3.637,1.04a.612.612,0,0,1-.167,1.2H19.821a1.871,1.871,0,0,1-.749-.16.9.9,0,0,0-1.026.145l-1.273,1.273a.872.872,0,0,0,.109,1.338,5.315,5.315,0,0,0,2.313.836v1.287a1.167,1.167,0,0,0,1.164,1.164h1.164a1.167,1.167,0,0,0,1.164-1.164v-1.28a4.092,4.092,0,0,0,3.586-4.582,4.268,4.268,0,0,0-3.12-3.47l-3.637-1.04a.612.612,0,0,1,.167-1.2h2.386a1.871,1.871,0,0,1,.749.16A.9.9,0,0,0,23.843,5.9l1.273-1.273a.872.872,0,0,0-.109-1.338,5.315,5.315,0,0,0-2.313-.836V1.164A1.167,1.167,0,0,0,21.53,0H20.366A1.167,1.167,0,0,0,19.2,1.164v1.28a4.092,4.092,0,0,0-3.586,4.582A4.268,4.268,0,0,0,18.737,10.5Zm20.1,13.369a2.184,2.184,0,0,0-2.931,0L29.56,29.24a4.23,4.23,0,0,1-2.749,1.018H18.686a1.166,1.166,0,0,1,0-2.328h5.375a2.329,2.329,0,0,0,.116-4.655H13.187A7.777,7.777,0,0,0,8.1,25.189L4.91,27.931H1.1A1.136,1.136,0,0,0,0,29.095v6.983a1.136,1.136,0,0,0,1.1,1.164H25.6a4.259,4.259,0,0,0,2.749-1.018l10.387-8.8a2.417,2.417,0,0,0,.095-3.557Z" fill="#fff"/>
                        </svg>
                    </div>
                    <label className=' text-gray-500'>No balloon payment at end of contract - just return the car.</label>
                </div>
            </div>
            <div className='my-10' style={bgImage(require('../../../assets/images/about-ilease-what-you-get-6.jpg'), 250)}>
                <div className='flex flex-col justify-center redOverlay text-white h-full w-full p-10'>
                    <p className='text-2xl border-b pb-5 md:w-2/3'>
                        Want to get out of your contract before end of agreed term? No hassle, we make it easier 
                        for you to return the car and walk away*.
                    </p>
                    <p className='mt-2'>*T&Cs apply.</p>
                </div>
            </div>
            <div className='flex flex-col my-10 text-gray-500'>
                <h1 className='text-red-600 font-bold uppercase'>Ongoing management of your lease</h1>
                <SquareBullet text="We'll automatically review your usage every 90 days, as you pay for what you use." />
                <SquareBullet 
                    text="If you're averaging less kilometers, per month, compared to your contract we'll reduce 
                    the mileage component and your charges (dependant on mileage change)." 
                />
                <SquareBullet 
                    text="If you're averaging more kilometers, per month, we'll increase the mileage component 
                    in the contract and the costs associated with the consumption." 
                />
                <SquareBullet text="We'll take a new average every 90 days and keep you informed as the contract proceeds." />
            </div>
        </ImagePreloader>
    );
};
