import React from 'react'
import { colors } from '../../shared/colors';
import { btnStyle, isError } from '../../shared/helpers/helper';
import { createValidate } from './validate';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { resetPassword } from '../../lib/services/auth.service';
import { useParams } from 'react-router-dom';
import { Success } from '../../shared/components/success';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';

export const ResetPassword = () => {

    const {id} = useParams();
    const [password, setPassword] = React.useState<string>('');
    const [confirmPassword, setConfirmPassword] = React.useState<string>('');
    const [passwordErrors, setPasswordErrors] = React.useState<string>('');
    const [password2Errors, setPassword2Errors] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isSent, setIsSent] = React.useState<boolean>(false);

    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [showPassword2, setShowPassword2] = React.useState<boolean>(false);

    const handleSubmit = () => {
        if(!isPasswordValid(password, setPasswordErrors, confirmPassword, setPassword2Errors)) return;
        setLoading(true);
        resetPassword(id as string, password)
        .then((res: boolean) => {
            if(res === false) {
                toast.error('Failed to reset password');
                setLoading(false);
                return;
            };
            setIsSent(true);
        });
    };

    if(isSent)
        return (
            <Success
                title="Password reset successful."
                message="Please proceed to login with your new password."
                returnUrl="/auth/login"
            />
        );

  return (
    <div className='flex flex-col mt-20 w-full text-white'>
        <h1 className='text-lg underline underline-offset-8 font-semibold' style={{ color: colors.primary }}>Reset Password</h1>
        <div className='grid grid-cols-1 gap-5 mt-10 md:w-1/2'>
            <Tooltip 
                title="Password should have at least 2 UPPERCASE letters, 3 lowercase letters 1 special character and 2 numbers."
                placement='top'
            >
                <TextField
                    label="New Password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    error={isError(passwordErrors)}
                    helperText={passwordErrors}
                    onChange={(e) => {
                    setPassword(e.target.value) 
                    setPasswordErrors(createValidate('password', e.target.value) as string);            
                    }}
                    onBlur={(e) => setPasswordErrors(createValidate('password', e.target.value, 'onBlur') as string)}
                    fullWidth
                    inputProps={{
                        endadornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Tooltip>
            <TextField
                label='Confirm Password'
                type={showPassword2 ? 'text' : 'password'}
                value={confirmPassword}
                error={isError(password2Errors)}
                helperText={password2Errors}
                onChange={(e) => {
                    setConfirmPassword(e.target.value) 
                    setPassword2Errors(createValidate('confirmPassword', e.target.value, '', password) as string);            
                }}
                onBlur={(e) => setPassword2Errors(createValidate('confirmPassword', e.target.value, 'onBlur', password) as string)}
                fullWidth
                inputProps={{
                    endadornment: (
                        <InputAdornment position="end">
                        <IconButton
                            onClick={() => setShowPassword2(!showPassword2)}
                            onMouseDown={() => setShowPassword2(!showPassword2)}
                        >
                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </div>
        <div className='flex flex-col items-center md:items-start my-10 md:my-2'>
            <div className='flex flex-col w-full md:hidden'>
                <LoadingButton
                    style={{ backgroundColor: loading ? 'gray' : colors.primary, color: 'white', borderRadius: 0 }}
                    onClick={handleSubmit}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<></>}
                    disabled={loading}
                    variant="contained"
                >
                    Reset
                </LoadingButton>
            </div>
            <div className='hidden md:block'>
                <LoadingButton
                    style={{...btnStyle as any, backgroundColor: loading ? 'gray' : colors.primary, textTransform: 'uppercase' }}
                    onClick={handleSubmit}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<></>}
                    disabled={loading}
                    variant="contained"
                >
                    Reset
                </LoadingButton>                
            </div>
        </div>
    </div>
  );
};

const isPasswordValid = (password: string, setPasswordErrors: any, confirmPassword: string, setPassword2Errors: any): boolean => {

  const password1 = createValidate('password', password, 'onBlur');
  const password2 = createValidate('confirmPassword', confirmPassword, 'onBlur', password);

  setPasswordErrors(password1);
  setPassword2Errors(password2);

  if(!password1 && !password2)
    return true
  else 
    return false;

};