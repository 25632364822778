import { LinksType } from "../types/links.type";
import { IconsEnum } from "../../../shared/enums/icon.enum";
import { IconResolver } from "../../../shared/helpers/icon-resolver";

export const Links: LinksType[] = [
    {
        text: 'About iLease',
        url: '/about-ilease',
        breadcrumb: ''
    },
    {
        text: 'What you get',
        url: '/about-ilease/what-you-get',
        breadcrumb: ' / What you get'
    },
    {
        text: 'Vehicle Options',
        url: '/about-ilease/vehicle-options',
        breadcrumb: ' / Vehicle Options'
    },
    {
        text: 'Price Comparison',
        url: '/about-ilease/price-comparison',
        breadcrumb: ' / Price Comparison'
    },
];

export const Vehicles = [
    {
        name: 'VW Polo Vivo',
        model: '1.4i Trendline',
        priceHead: 'R7521 pm',
        priceIncl: 'R264400 incl',
        monthly: 'R5402',
        balloon: 'R105760',
        maintenance: 'R881',
        insurance: 'R1763',
        annual: 'R53',
        total: 'R8219',
    },
    {
        name: 'VW T-Cross',
        model: '1.0 TSI Comfortline',
        priceHead: 'R9856 pm',
        priceIncl: 'R404000 incl',
        monthly: 'R8254',
        balloon: 'R161600',
        maintenance: 'R842 S.Plan',
        insurance: 'R2020',
        annual: 'R81',
        total: 'R11316',
    },
    {
        name: 'Toyota Fortuner',
        model: '2.4 GD-6 R/B A/T',
        priceHead: 'R15653 pm',
        priceIncl: 'R704800 incl',
        monthly: 'R12563',
        balloon: 'R281920',
        maintenance: 'R822 S.Plan',
        insurance: 'R2232',
        annual: 'R92',
        total: 'R15829',
    },
];

export const Journey = [
    {
        number: 1,
        title: 'Choose vehicle',
        icon: IconResolver(IconsEnum.JOURNEY1),
        body: `Take a look at our "Sample Pricing", see what example vehicle your planned expenditure can achieve
        (actual quotations will be specific to your choice of vehicle and usage).`
    },
    {
        number: 2,
        title: 'Pre-qualify',
        icon: IconResolver(IconsEnum.JOURNEY2),
        body: `If you decide to continue and wish a more detailed quotation on a specific vehicle please proceed with
        our Pre-approval process. Pre-approval is non-committal and confidential.`
    },
    {
        number: 3,
        title: 'Request quote',
        icon: IconResolver(IconsEnum.JOURNEY3),
        body: `Once you're pre-approved, you can request quotations, order your vehicle, sign the Lease agreement, e.t.c.`
    },
    {
        number: 4,
        title: 'Your Vehicle',
        icon: IconResolver(IconsEnum.JOURNEY4),
        body: `We will provide an order to the dealer. Following registration you can collect, or we will arrange 
        delivery of your vehicle.`
    },
]