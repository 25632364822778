import { SocialsType } from "../../../shared/components/types/socials.type";
import { IconsEnum } from "../../../shared/enums/icon.enum";

export const Socials: SocialsType[] = [
    {
        name: "Facebook",
        url: 'https://www.facebook.com/AvisSouthAfrica',
        icon: IconsEnum.FACEBOOK_BORDERED
    },
    {
        name: "Twitter",
        url: 'https://twitter.com/AvisSouthAfrica',
        icon: IconsEnum.TWITTER_BORDERED
    },
    {
        name: 'Instagram',
        url: 'https://www.instagram.com/avissouthafrica',
        icon: IconsEnum.INSTAGRAM_BORDERED
    },
    {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/avissouthafrica',
        icon: IconsEnum.LINKEDIN_BORDERED
    }
]