import { colors } from '../colors'
import { bgImage } from '../helpers/helper';

export const Banner = ({image, text, origin, breadcrumb}: any): React.JSX.Element => {
  return (
    <>
      <div style={bgImage(image, 200)}>
        <div className='flex flex-col justify-center h-full mx-auto max-w-7xl py-10 p-6 lg:px-8'>
          <h1 className={`text-2xl text-white ${text.toLowerCase().includes('ilease') ? '' : 'uppercase'}`} style={{borderColor: colors.primary}}>{text}</h1>
          <span className="w-32 md:w-56 border-t-2 mt-2" style={{borderColor: colors.primary}}></span>
        </div>
      </div>
      <div style={{backgroundColor: colors.primary}}>
        <div className='flex flex-row items-center h-8 mx-auto max-w-7xl px-6 lg:px-8 py-3' style={{backgroundColor: colors.primary}}>
          <div className='flex flex-row gap-x-1 text-white'>
            <p className='font-bold'>{origin}</p>
            <p>{breadcrumb}</p>
          </div>
        </div>
      </div>
    </>
  );
};
