import React from 'react'

export const ListItem = ({text}: any): React.JSX.Element => {
    return (
    <div className='flex flex-row gap-x-3 text-gray-500 mb-1'>
        <div className='bg-gray-300 mt-1' style={{height: 10, minWidth: 10, borderRadius: 100}}></div>
        <p className='text-sm'>{text}</p>
    </div>
    );
};
