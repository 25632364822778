import { Link } from 'react-router-dom';
import { colors } from '../colors';
import { TickSVG } from '../icons/icons';

export const Success = ({title, message, returnUrl}: any) => {
  return (
    <div className='flex flex-row justify-center items-center text-white text-lg mx-auto max-w-7xl px-6 lg:px-8'>
      <div className='flex flex-col justify-center items-center w-4/5 md:w-1/2 h-fit'>
        <TickSVG />
        <h1 className='text-center mt-10'>
          {title} {message}
        </h1>
        <Link
          to={returnUrl}
          className='w-fit px-10 py-1 text-center mt-10'
          style={{backgroundColor: colors.primary, color: 'white'}}
        >
          Okay
        </Link>
      </div>
    </div>
  );
};
