export enum IconsEnum {
    HOME,
    SEARCH,
    FILLED_QUESTION,
    FILLED_MAIL,
    INFO,
    DOCUMENT,
    FACEBOOK,
    TWITTER,
    INSTAGRAM,
    LINKEDIN,
    FACEBOOK_BORDERED,
    TWITTER_BORDERED,
    INSTAGRAM_BORDERED,
    LINKEDIN_BORDERED,
    JOURNEY1,
    JOURNEY2,
    JOURNEY3,
    JOURNEY4,
}