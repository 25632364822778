import { createQuote } from "../../lib/services/quote.service";
import { colors } from "../colors";
import { AccountDetailsDto } from "../dtos/account-details.dto";
import { AS400Dto } from "../dtos/as400-dto.ts";

export const handleMutation = async (user: any, mutation: any): Promise<any> => {
  let mut = Object.assign({}, mutation);
  mut.userId = user._id;
  const data = {
    mutations: [
      {
        create: {
          ...mut
        }
      }
    ]
  };
  return await createQuote(data);
};

export const isError = (value: string): boolean => {
  if(value) return true
  else return false;
};

const getGenderFromID = (idNumber: string) => {
  const genderDigits = parseInt(idNumber.substring(6, 10));
  if (genderDigits === 5111) {
    return 'F'
  }
  else if (genderDigits < 5000) {
    return 'F'
  }
  else {
    return 'M'
  };
};

const getInitials = (name: string) => {
  let initials: string = '';
  let names = name.split(' ');
  names.forEach(name => {
    initials += name[0].toUpperCase();
  });
  return initials;
};

export const createAS400Profile = (form: AccountDetailsDto, amount?: number): AS400Dto => {
  const data: AS400Dto = {
    customerId: '',
    title: form.title,
    initials: getInitials(form.firstName),
    fullnames: form.firstName,
    surname: form.lastName,
    identificationNumber: form.idNumber,
    datetext: getDateOfBirth(form.idNumber),
    eMail: form.emailAddress,
    telephoneNumber: form.cellNumber,
    sex: getGenderFromID(form.idNumber),
    address1: form.address,
    address2: form.apartment,
    address3: form.city,
    address4: form.province,
    postalCode: form.postalCode,
    approvedAmount: amount as number
  };
  return data;
};

export const resolveUrl = (path: string): string => {
  const paths = path.split('/');
  return '/' + paths[1];
};

const getDateOfBirth = (idNumber: string): string => {
  const intYear = parseInt(idNumber.substring(0, 2));
  const intMonth = parseInt(idNumber.substring(2, 4));
  const intDays = parseInt(idNumber.substring(4, 6));
  const sysYear = parseInt(new Date().getFullYear().toString());
  const birthYear = (Math.floor((sysYear - intYear) / 100) * 100) + intYear;
  return convertDate(birthYear, intMonth, intDays);
}

const convertDate = (year: number, month: number, day: number): string => {
  let dob = '';
  dob += year.toString();
  if (month < 10)
    dob += ('-0' + month) as string;
  else
    dob += ('-' + month) as string;
  if (day < 10)
    dob += ('-0' + day) as string;
  else
    dob += ('-' + day) as string;
  return dob
}

export const bgImage = (image: any, height: number | string) => (
  {
    width: '100%',
    height: height,
    backgroundImage: `url(${image})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }
);

export const btnStyle = {
  backgroundColor: colors.primary, 
  paddingRight: 80, 
  textTransform: 'none', 
  color: '#fff', 
  borderRadius: 0, 
  marginTop: 20,
  fontWeight: 500,
  fontSize: 15,
  paddingBlock: 10
};

export const formatNumber = (value: string, inputType: string): string => {
  const numberPattern:RegExp = /[0-9]/;
  if(!numberPattern.test(value[value.length-1])) return value.substring(0, value.length - 1);
  if(value.length > 12) return value.substring(0, value.length - 1);
  let number: string = '';
  if(value.length === 3 && inputType != 'deleteContentBackward') number += insertAtIndex(value, '-', 3);
  else if(value.length === 7 && inputType != 'deleteContentBackward') number += insertAtIndex(value, '-', 7)
  else number = value;
  return number;
};

const insertAtIndex = (original: string, toInsert: string, index: number): string => {
  return original.slice(0, index) + toInsert + original.slice(index);
};