import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../shared/colors';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { btnStyle, isError } from '../../shared/helpers/helper';
import { LoginDto } from '../../shared/dtos/login.dto';
import { loginValidate } from './validate';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch } from '../../redux/store';
import { login } from '../../lib/services/auth.service';
import { setUser } from '../../redux/features/userSlice';
import { toast } from 'react-toastify';

export const Login = () => {

  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [form, setForm] = React.useState<LoginDto>({
    emailAddress: '',
    password: '',
  });

  const [validator, setValidator] = React.useState<any>({
    emailAddress: '',
    password: '',
  });

  const handleLogin = async () => {
    if(!isFormValid(form, validator, setValidator)) return;
    setLoading(true);
    const user = await login(form);
    if(user.length > 0) {
      dispatch(setUser({user: user[0], token: null}));
      return;
    } else
    toast.error('Incorrect email address or password entered!');
    setLoading(false);;
  };

  return (
    <div className='flex flex-col mt-20 w-full text-white'>
      <div className='flex flex-row uppercase'>
        <h1 className='text-lg underline underline-offset-8 font-semibold' style={{ color: colors.primary }}>Login</h1>
        <Link
          to={'/auth/create-account'}
          className='text-lg ml-5'
        >Create Account
        </Link>
      </div>
      <div className='grid grid-cols-1 gap-5 mt-10 md:w-1/2'>
        <TextField
          label="Email Address"
          type="text"
          variant="outlined"
          required
          value={form.emailAddress}
          error={isError(validator.emailAddress)}
          helperText={validator.emailAddress}
          onChange={(e) => {
            setForm({ ...form, emailAddress: e.target.value });
            setValidator({ ...validator, emailAddress: loginValidate('emailAddress', e.target.value) });
          }}
          onBlur={(e) => setValidator({ ...validator, emailAddress: loginValidate('emailAddress', e.target.value, 'onBlur') })}
          fullWidth 
        />
        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={form.password}
          variant="outlined"
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>)
          }}
          error={isError(validator.password)}
          helperText={validator.password}
          onChange={(e) => {
            setForm({ ...form, password: e.target.value });
            setValidator({ ...validator, password: loginValidate('password', e.target.value) });
          } }
          onBlur={(e) => setValidator({ ...validator, password: loginValidate('password', e.target.value, 'onBlur') })}
          fullWidth 
        />
      </div>
      <div className='flex flex-col items-center md:items-start my-10 md:my-2'>
        <div className='flex flex-col w-full md:hidden'>
          <LoadingButton
            style={{ backgroundColor: loading ? 'gray' : colors.primary, color: 'white', borderRadius: 0 }}
            onClick={handleLogin}
            loading={loading}
            loadingPosition="start"
            disabled={loading}
            variant="contained"
          >
            <span>Login</span>
          </LoadingButton>
        </div>
        <div className='hidden md:block'>
          <LoadingButton
            style={{...btnStyle as any, backgroundColor: loading ? 'gray' : colors.primary, textTransform: 'uppercase' }}
            onClick={handleLogin}
            loading={loading}
            loadingPosition="start"
            disabled={loading}
            variant="contained"
          >
            <span>Login</span>
          </LoadingButton>
        </div>
        <Link
          to='/auth/forgot-password'
          className='text-sm mt-5 hover:text-blue-500'
        >Forgot password?</Link>
      </div>
    </div>
  );
};

const isFormValid = (form: LoginDto, validator: any, setValidator: any): boolean => {
  const emailAddress = loginValidate('emailAddress', form.emailAddress, 'onBlur');
  const password = loginValidate('password', form.password, 'onBlur');
  setValidator({
    emailAddress: emailAddress,
    password: password,
  });

  if(
    !emailAddress &&
    !password
  )return true
  else return false;
};