import React from 'react';
import { Banner } from '../../../shared/components/banner';

export const Outcome = (): JSX.Element => {
    
    React.useEffect(() => {
      window.scrollTo(0, 0)
    }, []);
    
    return (
        <>
            <Banner image={require('../../../assets/images/prequalify.jpg')} text="Insurance Questionnaire" origin='Insurance Questionnaire' />
            <div className='flex flex-col mx-auto max-w-7xl py-10 p-6 lg:px-56 gap-y-10 text-center text-gray-600'>
                <p className='text-md'>
                    <span className='font-semibold'>
                        Regrettably, we are unable to proceed with your Avis iLease pre-approval 
                        due to disqualification based on Insurance history.
                    </span>
                    <br />
                    <br />
                    However, we may be able to assist you with our Avis MiniLease service, please click 
                    on this link to choose your Monthly Car Rental or Subscription option:”<br />
                    <a target='_blank' href='https://www.avis.co.za/long-term-car-hire/choose-your-monthly-car-hire-option' className='text-red-600 hover:underline' rel="noreferrer">
                        https://www.avis.co.za/long-term-car-hire/choose-your-monthly-car-hire-option
                    </a>
                </p>
            </div>
        </>
    );
};
