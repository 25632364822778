import { FilterDto } from "../../shared/dtos/filter.dto";
import { client } from "../lib";

export const getVehicles = async () => {
    return await client.fetch('*[_type == "vehicle"]');
};

export const getVehicleDetails = async (id: string) => {
    return await client.fetch('*[_type == "vehicle" && _id == $id]', {id});
};

export const filterVehicles = async (filters: FilterDto) => {
    const query = `*[_type == "vehicle" && (make in $makes || bodyType in $bodyTypes || model match $model)]
    {
      _id,
      make,
      model,
      image,
      bodyType,
      options[] {
        duration,
        price,
        kilometers
      }
    }`;
    return await client.fetch(query, {makes: filters.makes, model: filters.model, bodyTypes: filters.bodyTypes});
};