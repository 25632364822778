import React from 'react'
import { getSampleDocuments } from '../../lib/services/sample-document.service';
import { colors } from '../../shared/colors';
import { Link } from 'react-router-dom';
import { Banner } from '../../shared/components/banner';
import { CustomLoading } from '../../shared/components/custom-loading';
import { ImagePreloader } from '../../shared/helpers/image-preloader';

const imageUrls = [
  require('../../assets/images/sample-document.jpg')
];

export const SampleDocuments = (): JSX.Element => {

  const [documents, setDocuments] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getDocs().then(() => setLoading(false));
  },[]);

  const getDocs = async () =>{
    setDocuments(await getSampleDocuments());
  };

  if(loading) return <CustomLoading />

  return (
    <ImagePreloader images={imageUrls}>
      <Banner image={require('../../assets/images/sample-document.jpg')} text='Sample Documents' origin='Sample Documents' />
      <div className='mx-auto max-w-7xl py-10 p-6 lg:px-8 text-gray-600' style={{minHeight: '50vh'}}>
        <div className='flex flex-col gap-y-3'>
          {
            documents.map((doc: any) =>
              <div className='flex flex-col md:flex-row border-b-2 justify-between items-center pb-2'>
                <div className='flex flex-col'>
                  <label className='font-semibold text-md' style={{color: colors.primary}}>{doc.title}</label>
                  <label className='text-sm'>File size: {((doc.size /1000)/1000).toFixed(2)}mb</label>
                </div>
                <div className='flex flex-row justify-center mt-4 md:mt-0'>
                  <Link 
                    to={doc.docUrl}
                    target="_blank"
                    className='hover:opacity-70 px-5 py-1 text-white fornt-semibold'
                    style={{backgroundColor: colors.primary}}
                  >
                    Download
                  </Link>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </ImagePreloader>
  )
}
