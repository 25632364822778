import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface UserState {
    user: any | null;
    token: string | null;
};

const initialState: UserState = {
    user: null,
    token: null
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<UserState>) => {
            // console.log("setUser")
            // state.user = null;
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        logout: (state) =>{
            state.user = null;
            state.token = null;
        },
        clearUser: (state) => {
            state.user = null;
            state.token = null;
        }
    },
    // extraReducers: (bulder) => {
    //     bulder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) =>{
    //         state.user = payload.user;
    //         state.token = payload.token;
    //     });
    // }
});

export const { setUser, logout, clearUser } = userSlice.actions;
// export const getToken = (state: UserState) => state.token;
export default userSlice.reducer;