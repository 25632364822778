export const createValidate = (component: string, value: string, condition?: string, extraValue?: string) => {
    if(component === 'firstName' || component === 'lastName'){
        if(value){
            const namePattern:RegExp = /^(([A-Za-z]+[\-\']?)*([A-Za-z]+)?\s?)+([A-Za-z]+[\-\']?)*([A-Za-z]+)?$/;
            if(!namePattern.test(value)) return 'Only alphabetic characters are allowed';
        };
        if(!value && component === 'firstName') return 'Full name is required';
        if(!value && component === 'lastName') return 'Last name is required';
    };
    
    if(component === 'emailAddress'){
        if(!value) return 'Email address is required';        
        if(condition === 'onBlur'){
            const emailPattern:RegExp =  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if(!emailPattern.test(value)) return 'Invalid email address entered';
        };
    };

    if(component === 'cellNumber'){
        if(!value) return 'Cell phone number is required';

        if(condition === 'onBlur'){
            const numberPattern:RegExp = /^(\+27|0)[6-8][0-9]{8}?$/;
            if(!numberPattern.test(value.replaceAll('-', ''))) return `Invalid cell phone number entered`;
        };
;    }

    if(component === 'idNumber'){
        if(!value) return 'ID number is required';
        if(condition === 'onBlur'){
            const numberPattern:RegExp = /^(([0-9]{2})(0|1)([0-9])([0-3])([0-9]))([ ]?)(([0-9]{4})([ ]?)([0-1][8]([ ]?)[0-9]))?$/;
            if(!numberPattern.test(value)) return 'Invalid ID number entered';
        };
    };

    if(component === 'password'){
        if(!value) return 'Password is required';
        if(condition === 'onBlur'){
            const passwordPattern:RegExp = /^(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/;
            if(!passwordPattern.test(value)) return 'Password does not conform to pattern';
            if(value.length < 8) return 'Password must be atleast 8 digits';
        };
    };

    if(component === 'confirmPassword'){
        if(!value) return 'Confirm Password is required';
        if(value !== extraValue) return 'Passwords do not match';
    };
    return null;
}

export const loginValidate = (component: string, value: string, condition?: string) => {
    if(component === 'emailAddress' || component === 'password'){
        if(component === 'emailAddress' && !value){
            return 'Enter email address';
        }
        if(component === 'password' && !value){
            return 'Enter password';
        }
    }
}