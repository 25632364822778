import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { colors } from '../../../shared/colors';

export const ListItem = ({text}: any): React.JSX.Element => {
    return (
        <div className='flex flex-row gap-x-2' style={{width: 300}}>
            <CheckCircleIcon style={{fontSize: 18, color: colors.primary, marginTop: 3}} />
            <p className='text-sm text-gray-500'>{text}</p>
        </div>
    );
};
