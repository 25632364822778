import { IconsEnum } from "../enums/icon.enum";
import { DocumentSVG, FacebookBorderedSVG, FacebookSVG, FilledMailSVG, FilledQuestionSVG, HomeSVG, InfoSVG, InstagramBorderedSVG, InstagramSVG, Journey1SVG, Journey2SVG, Journey3SVG, Journey4SVG, LinkedinBorderedSVG, LinkedinSVG, SearchSVG, TwitterBorderedSVG, TwitterSVG } from '../icons/icons';

export const IconResolver = (icon: IconsEnum): JSX.Element | undefined => {
    switch (icon) {
        case IconsEnum.HOME:
            return <HomeSVG />
        case IconsEnum.SEARCH:
            return <SearchSVG />
        case IconsEnum.FILLED_QUESTION:
            return <FilledQuestionSVG />
        case IconsEnum.FILLED_MAIL:
            return <FilledMailSVG />
        case IconsEnum.INFO:
            return <InfoSVG />
        case IconsEnum.DOCUMENT:
            return <DocumentSVG />
        case IconsEnum.FACEBOOK:
            return <FacebookSVG />
        case IconsEnum.TWITTER:
            return <TwitterSVG />
        case IconsEnum.INSTAGRAM:
            return <InstagramSVG />
        case IconsEnum.LINKEDIN:
            return <LinkedinSVG />
        case IconsEnum.FACEBOOK_BORDERED:
            return <FacebookBorderedSVG />
        case IconsEnum.TWITTER_BORDERED:
            return <TwitterBorderedSVG />
        case IconsEnum.INSTAGRAM_BORDERED:
            return <InstagramBorderedSVG />
        case IconsEnum.LINKEDIN_BORDERED:
            return <LinkedinBorderedSVG />
        case IconsEnum.JOURNEY1:
            return <Journey1SVG />
        case IconsEnum.JOURNEY2:
            return <Journey2SVG />
        case IconsEnum.JOURNEY3:
            return <Journey3SVG />
        case IconsEnum.JOURNEY4:
            return <Journey4SVG />
        default:
            break;
    };
  };