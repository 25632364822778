import { Theme, createTheme, outlinedInputClasses } from "@mui/material";

export const customAuthTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-borderColor': '#fff',
            '--TextField-borderHoverColor': '#fff',
            '--TextField-borderFocusedColor': '#fff',
            '& label.Mui-focused': {
              color: 'var(--TextField-borderFocusedColor)',
            },
            '& label': {
              color: 'var(--TextField-borderFocusedColor)',
            },
            '& input': {
              color: 'var(--TextField-borderFocusedColor)',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-borderColor)',
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-borderHoverColor)',
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-borderFocusedColor)',
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&:before': {
              borderBottom: '2px solid var(--TextField-borderColor)',
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '2px solid var(--TextField-borderHoverColor)',
            },
            '&.Mui-focused:after': {
              borderBottom: '2px solid var(--TextField-borderFocusedColor)',
            },
          },
        },
      },
    },
  });