import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AS400Dto } from "../../shared/dtos/as400-dto.ts";

export interface AS400State {
    as400Profile: AS400Dto | null;
};

const initialState: AS400State = {
    as400Profile: null,
};

const as400Slice = createSlice({
    name: "as400Profile",
    initialState,
    reducers: {
        setAS400Profile: (state, action: PayloadAction<AS400State>) => {
            state.as400Profile = action.payload.as400Profile;
        }
    }
});

export const { setAS400Profile } = as400Slice.actions;
export default as400Slice.reducer;