import React from 'react'
import { Journey } from '../data/data';
import { IleaseJourneyCard } from '../components/ilease-journey-card';

export const AboutIlease = (): React.JSX.Element => {
  
    React.useEffect(() => {
      window.scrollTo(0, 0);
    });
    
    return (
        <div className='flex flex-col flex-wrap'>
            <h1 className='text-red-600 font-bold uppercase'>Moving your way is easier...our way</h1>
            <p className='text-gray-500 my-5'>
                We'll buy the vehicle of your choice and lease it to you, for up to 60 months and 
                with your mileage requirement - you only pay for what you use.
            </p>
            <h1 className='text-red-600 font-bold'>THE iLEASE JOURNEY</h1>
            <div className='grid grid-cols-1 md:grid-cols-4 gap-5 my-10'>
                {Journey.map((journey: any, index: number) => <IleaseJourneyCard key={index} journey={journey} />)}
            </div>
        </div>
    );
};
