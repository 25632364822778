import {
    createBrowserRouter,
    createRoutesFromElements,
    Link,
    Navigate,
    Outlet,
    Route,
  } from "react-router-dom";
import { Login } from '../pages/auth/login';
import { CreateAccount } from "../pages/auth/create-account";
import Header from "../shared/components/header/header";
import { Footer } from "../shared/components/footer/footer";
import { LetsConnect } from "../pages/let's-connect/let's-connect";
import { Faqs } from "../pages/faqs/faqs";
import { VerifyEmail } from "../pages/auth/verify-email";
import { ForgotPassword } from "../pages/auth/forgot-password";
import { ResetPassword } from "../pages/auth/reset-password";
import { SampleDocuments } from "../pages/sample-documents/sample-documents";
import { QuotationRequest } from "../pages/quotation-request/quotation-request";
import { useLocation } from 'react-router-dom';
import { Homepage } from "../pages/homepage/homepage";
import { MyCar } from "../pages/my-car/my-car";
import { AboutIlease } from "../pages/about-ilease/screens/about-ilease";
import { colors } from "../shared/colors";
import { ThemeProvider, useTheme } from "@mui/material";
import { customAuthTheme } from "../shared/helpers/custom-auth-theme";
import { ImagePreloader } from "../shared/helpers/image-preloader";
import { AboutIleaseLayout } from "../pages/about-ilease/about-ilease-layout";
import { WhatYouGet } from "../pages/about-ilease/screens/what-you-get";
import { VehicleOptions } from "../pages/about-ilease/screens/vehicle-options";
import { PriceComparison } from "../pages/about-ilease/screens/price-comparison";
import React from "react";

const imageUrls = [
  require('../assets/images/login.jpg')
];

const NoAuth = (): React.JSX.Element => {

  const outerTheme = useTheme();
  const location = useLocation();
  const isAuth = location.pathname.startsWith('/auth');
  
  return(
    <>
      { !isAuth && <Header />}
      { !isAuth 
        ?
          <div style={{paddingTop: 80, minHeight: '70vh'}}>
            <Outlet />
          </div>
        :
          <ImagePreloader images={imageUrls}>
            <div className="authBgImage h-full w-full">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <Link
                  to='/'
                >
                  <div className='w-fit' style={{backgroundColor: colors.primary}}>
                    <img src={require('../assets/images/logo.png')} alt='logo' style={{height: 70}} />
                  </div>
                </Link>
              </div>
              <div className="mx-auto max-w-7xl p-6 lg:px-8 h-full w-full">
                <ThemeProvider theme={customAuthTheme(outerTheme)}>
                  <Outlet/>
                </ThemeProvider>
              </div>
            </div>
          </ImagePreloader>
      }
      { !isAuth && <Footer />}
    </>
  );
};

export const noauthRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<NoAuth />}>
      <Route index element={<Homepage />} />
      <Route path='/auth/login' element={<Login />} />
      <Route path='/auth/create-account' element={<CreateAccount />} />
      <Route path='/auth/forgot-password' element={<ForgotPassword />} />
      <Route path='/verify-email/:id' element={<VerifyEmail />} />
      <Route path='/auth/reset-password/:id' element={<ResetPassword />} />
      <Route path='/about-ilease' element={<AboutIleaseLayout />}>
          <Route index element={<AboutIlease />} />
          <Route path='what-you-get' element={<WhatYouGet />} />
          <Route path='vehicle-options' element={<VehicleOptions />} />
          <Route path='price-comparison' element={<PriceComparison />} />
      </Route>
      <Route path='/my-car' element={<MyCar />} />
      <Route path='/faqs' element={<Faqs />} />
      <Route path="/let's-connect" element={<LetsConnect />} />
      <Route path='/sample-documents' element={<SampleDocuments />} />
      <Route path='/insurance-questionnaire' element={<Navigate replace to="/auth/login" />} />
      <Route path='/quotation-request/:id' element={<QuotationRequest />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Route>
  )

);
