import React from 'react'
import { Button, TextField } from '@mui/material'
import { ContactUsDto } from '../../shared/dtos/contact-us.dto'
import { Banner } from '../../shared/components/banner'
import { ImagePreloader } from '../../shared/helpers/image-preloader'
import { sendContactUs } from '../../lib/services/avis-api.service'
import { toast } from 'react-toastify'
import { bgImage, btnStyle } from '../../shared/helpers/helper'
import { IconResolver } from '../../shared/helpers/icon-resolver'
import { Socials } from './data/socials'

const imageUrls = [
  require('../../assets/images/letsconnect.jpg'),
  require('../../assets/images/letsconnect1.jpg'),
  require('../../assets/images/letsconnect2.jpg'),
  require('../../assets/images/letsconnect3.jpg'),
  require('../../assets/images/letsconnect4.jpg'),
];

export const LetsConnect = () => {

  const [form, setForm] = React.useState<ContactUsDto>({
    firstName: '',
    lastName: '',
    emailAddress: '',
    message: '',
  });

  const handleContact = () => {
    sendContactUs(form)
    .then(() => {
      setForm({
        firstName: '',
        lastName: '',
        emailAddress: '',
        message: '',
      });
      toast.success('Message sent successfully.');
    });
  }
  
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <ImagePreloader images={imageUrls}>
      <Banner image={require('../../assets/images/letsconnect.jpg')} text="Let's Connect" origin="Let's Connect" />
      <div className='mx-auto max-w-7xl py-10 p-6 lg:px-8'>
        <h1 className='text-red-600 font-bold uppercase'>Connect Humanity</h1>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-10 mt-5'>
          <div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
              <TextField
                label="First Name"
                type="text"
                variant="outlined"
                value={form.firstName}
                onChange={(e) => setForm({...form, firstName: e.target.value}) }
                fullWidth
              />
              <TextField
                label="Last Name"
                type="text"
                variant="outlined"
                value={form.lastName}
                onChange={(e) => setForm({...form, lastName: e.target.value}) }
                fullWidth
              />
              <TextField
                label="Email Address"
                type="text"
                variant="outlined"
                className='col-span-full'
                value={form.emailAddress}
                onChange={(e) => setForm({...form, emailAddress: e.target.value}) }
                fullWidth
              />
              <TextField
                label="Message"
                type="text"
                variant="outlined"
                multiline
                rows={8}
                className='col-span-full'
                value={form.message}
                onChange={(e) => setForm({...form, message: e.target.value}) }
                fullWidth
              />
            </div>
          </div>
          <div className='hidden md:grid grid-cols-2 gap-0 text-white'>
            <div style={bgImage(require('../../assets/images/letsconnect3.jpg'), '100%')}>
              <div className="flex flex-col items-center justify-center h-1-2 w-full redOverlay text-sm">
                <h1 className='font-bold'>Location</h1>
                <p>
                  Zeda Limited
                  <br />
                  2 Sysie Road
                  <br />
                  Croydon 1691
                </p>
              </div>
            </div>
            <div style={bgImage(require('../../assets/images/letsconnect1.jpg'), '100%')}>
              <div className="flex flex-col items-center justify-center h-1-2 w-full redOverlay text-sm">
                <h1 className='font-bold'>Email</h1>
                <a href='mailto:iLease@avis.co.za' className='hover:text-blue-800'>iLease@avis.co.za</a>
              </div>
            </div>
            <div style={bgImage(require('../../assets/images/letsconnect2.jpg'), '100%')}>
              <div className="flex flex-col items-center justify-center h-full w-full redOverlay text-sm">
                <h1 className='font-bold'>Telephone</h1>
                <a href='tel:0119233500' className='hover:text-blue-800'>0119233500</a>
              </div>
            </div>
            <div style={bgImage(require('../../assets/images/letsconnect4.jpg'), '100%')}>
              <div className='flex flex-row justify-center items-center h-full w-full redOverlay text-sm'>
                <div className="grid grid-cols-2 gap-2 items-center justify-center">
                  {
                    Socials.map((social, index: number) => 
                      <a href={social.url} target='_blank' key={index} rel="noreferrer" className='hover:scale-125 w-fit'>
                        {IconResolver(social.icon)}
                      </a>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=''>
          <Button
            onClick={() => handleContact()}
            style={{...btnStyle as any}}
          >Submit</Button>
        </div>
      </div>
    </ImagePreloader>
  );
};
