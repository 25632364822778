import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface GeneralState {
    toDoQuestionnaire: boolean;
    toDoPrequalify: boolean;
};

const initialState: GeneralState = {
    toDoQuestionnaire: false,
    toDoPrequalify: false,
};

const generalSlice = createSlice({
    name: "general",
    initialState,
    reducers: {
        setTodoQuestionnaire: (state, action: PayloadAction<boolean>) => {
            state.toDoQuestionnaire = action.payload;
        },
        setToDoPrequalify: (state, action: PayloadAction<boolean>) => {
            state.toDoPrequalify = action.payload;
        },
    }
});

export const { setTodoQuestionnaire, setToDoPrequalify } = generalSlice.actions;
export default generalSlice.reducer;