import React from "react";
import { colors } from "../../../shared/colors";
import { OffersType, offers } from "../data/offers";

export const OffersSection = (): React.JSX.Element => {
    return (
        <div className="flex flex-col">
            <h1 className="text-gray-600 text-2xl">Choose how you want to move</h1>
            {/* <span className="w-56 border-t mt-2" style={{borderColor: colors.primary}}></span> */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 my-10">
                {
                    offers.map((offer: OffersType, index: number) => 
                        <a 
                            key={index}
                            href={offer.link} 
                            target={offer.target} 
                            style={{backgroundColor: colors.primary, height: 550, color: '#fff'}}
                        >
                            <img src={offer.image} className='w-full object-cover' alt='img' style={{height: 470}} />
                            <div className='px-5 pt-3'>
                                <h1 className='font-semibold text-lg'>{offer.title}</h1>
                                <p className="whitespace-nowrap overflow-hidden">{offer.text}</p>
                            </div>
                        </a>
                    )
                }
            </div>
        </div>
    );
};