import React from 'react'
import { colors } from '../../shared/colors';
import { btnStyle, isError } from '../../shared/helpers/helper';
import { createValidate } from './validate';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { forgotPassword } from '../../lib/services/auth.service';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';
import { Success } from '../../shared/components/success';
import { toast } from 'react-toastify';

export const ForgotPassword = () => {

    const [emailAddress, setEmailAddress] = React.useState<string>('');
    const [emailAddressErrors, setEmailAddressErrors] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isSent, setIsSent] = React.useState<boolean>(false);

    const handleSubmit = () => {
        if(!isEmailValid(emailAddress, setEmailAddressErrors)) return;
        setLoading(true);
        forgotPassword(emailAddress, window.location.origin)
        .then((res) => {
            if(res !== 200) {
                if(res=== 404){
                    toast.error('Internal Server Error!');
                    setLoading(false);
                    return;
                };
                toast.error('Account not found!');
                setLoading(false);
                return;
            };
            setIsSent(true);
        });
    };

    if(isSent)
        return (
            <Success
                title="Password reset request successful."
                message="Please check your emails for further instructions."
                returnUrl="/auth/login"
            />
        );

  return (
    <div className='flex flex-col mt-20 w-full md:w-1/2 text-white'>
        <div className='flex flex-row'>
            <Link
                to='/auth/login'
                className='mt-1 mr-4'
            >
                <ArrowBackIosIcon style={{color: colors.primary}} />
            </Link>
            <label className='text-lg underline underline-offset-8 font-semibold mb-10' style={{color: colors.primary}}>Forgot Password</label>
        </div>
        <TextField
            label="Email Address"
            type="text"
            variant="outlined"
            required
            value={emailAddress}
            error={isError(emailAddressErrors)}
            helperText={emailAddressErrors}
            onChange={(e: any) => {
                setEmailAddress(e.target.value) 
                setEmailAddressErrors(createValidate('emailAddress', e.target.value) as string);            
            }}
            onBlur={(e: any) => setEmailAddressErrors(createValidate('emailAddress', e.target.value, 'onBlur') as string)}
            fullWidth
        />
        <div className='flex flex-col items-center md:items-start my-10 md:my-2'>
            <div className='flex flex-col w-full md:hidden'>
                <LoadingButton
                    className='w-full md:w-44'
                    style={{backgroundColor: loading ? 'gray' : colors.primary, color: 'white', borderRadius: 0}}
                    onClick={handleSubmit}
                    loading={loading}
                    loadingPosition="start"
                    disabled={loading}
                    >
                    <span>Submit</span>
                </LoadingButton>
            </div>
            <div className='hidden md:block'>
                <LoadingButton
                    className='w-full md:w-44'
                    style={{...btnStyle as any, backgroundColor: loading ? 'gray' : colors.primary, textTransform: 'uppercase'}}
                    onClick={handleSubmit}
                    loading={loading}
                    loadingPosition="start"
                    disabled={loading}
                    >
                    <span>Submit</span>
                </LoadingButton>
            </div>
        </div>
    </div>
  );
};

const isEmailValid = (emailAddress: string, setEmailAddressErrors: any): boolean => {

  const email = createValidate('emailAddress', emailAddress, 'onBlur');

  setEmailAddressErrors(email);

  if(!email)
    return true
  else 
    return false;

};