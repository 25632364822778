import React from 'react'
import { getFaqs } from '../../lib/services/faq.service';
import { Faq } from './components/faq';
import { Banner } from '../../shared/components/banner';
import { CustomLoading } from '../../shared/components/custom-loading';
import { ImagePreloader } from '../../shared/helpers/image-preloader';
import { colors } from '../../shared/colors';

const imageUrls = [
  require('../../assets/images/faqs.jpg')
];

export const Faqs = () => {

  const [faqs, setFaqs] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    fetchAll();
  },[]);

  const fetchAll = async () => {
    setFaqs(await getFaqs());
    setLoading(false);
  };

  if(loading) return <CustomLoading />
  
  return (
    <ImagePreloader images={imageUrls}>
      <Banner image={require('../../assets/images/faqs.jpg')} text="FAQ's" origin="FAQs" />
      <div className='flex flex-col mx-auto max-w-7xl py-10 p-6 lg:px-8'>
        <h1 className='font-bold uppercase' style={{color: colors.primary}}>FREQUENTLY ASKED QUESTIONS</h1>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-5 mt-5'>
          {
            faqs.map((faq: any, index: number) =>
              <Faq key={index} faq={faq} />
            )
          }
        </div>
      </div>
    </ImagePreloader>
  )
}
