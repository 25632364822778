import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { Navigator } from './navigation/navigator'
import { PersistGate } from 'redux-persist/integration/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { initGA, pageview } from './gtag';

function App() {

  React.useEffect(() => {
    initGA();
  }, []);

  React.useEffect(() => {
    pageview(window.location.pathname);
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Navigator />
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  );
}

export default App;
