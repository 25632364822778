export enum ComponentEnum {
    TITLE = 'title',
    ID_NUMBER = 'IdNumber',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    EMAIL_ADDRESS = 'emailAddress',
    CELL_NUMBER = 'cellNumber',
    ADDRESS = 'address',
    APARTMENT = 'apartment',
    CITY = 'city',
    PROVINCE = 'province',
    POSTAL_CODE = 'postalCode',
}