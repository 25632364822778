function gtag() {
    window.dataLayer.push(arguments);
}
// gtag.js
export const initGA = () => {
    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    gtag('config', 'G-E2PMEBHD8R');
};
  
export const pageview = (url) => {
    gtag('config', 'G-E2PMEBHD8R', {
        page_path: url,
    });
};
  