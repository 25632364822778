import React from "react";
import { Navigate, Outlet, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Header from "../shared/components/header/header";
import { Footer } from "../shared/components/footer/footer";
import { Homepage } from "../pages/homepage/homepage";
import { LetsConnect } from "../pages/let's-connect/let's-connect";
import { Faqs } from "../pages/faqs/faqs";
import { SampleDocuments } from "../pages/sample-documents/sample-documents";
import { InsuranceQuestionnaire } from "../pages/insurance-questionnaire/insurance-questionnaire";
import { QuotationRequest } from "../pages/quotation-request/quotation-request";
import { Authentication } from "../shared/components/authentication";
import { MyCar } from "../pages/my-car/my-car";
import { Prequalify } from "../pages/prequalify/prequalify";
import { AboutIleaseLayout } from "../pages/about-ilease/about-ilease-layout";
import { WhatYouGet } from "../pages/about-ilease/screens/what-you-get";
import { VehicleOptions } from "../pages/about-ilease/screens/vehicle-options";
import { PriceComparison } from "../pages/about-ilease/screens/price-comparison";
import { AboutIlease } from "../pages/about-ilease/screens/about-ilease";


const Root = ():React.JSX.Element => {
    return(
        <>
            <Header />
                <div style={{paddingTop: 80, minHeight: '70vh'}}>
                    <Outlet />
                </div>
            <Footer />
        </>
    );
};

export const authRouter = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Root />}>
            <Route index element={<Homepage />} />
            <Route path="auth/login" element={<Navigate replace to="/authenticating..." />} />
            <Route path='/auth/create-account' element={<Navigate replace to="/authenticating..." />} />
            <Route path='/verify-email/:id' element={<Navigate replace to="/authenticating..." />} />
            <Route path='/authenticating...' element={<Authentication />} />
            <Route path='/about-ilease' element={<AboutIleaseLayout />}>
                <Route index element={<AboutIlease />} />
                <Route path='what-you-get' element={<WhatYouGet />} />
                <Route path='vehicle-options' element={<VehicleOptions />} />
                <Route path='price-comparison' element={<PriceComparison />} />
            </Route>
            <Route path='/my-car' element={<MyCar />} />
            <Route path='/faqs' element={<Faqs />} />
            <Route path="/let's-connect" element={<LetsConnect />} />
            <Route path='prequalify' element={<Prequalify />} />
            <Route path='/sample-documents' element={<SampleDocuments />} />
            <Route path='/insurance-questionnaire' element={<InsuranceQuestionnaire />} />
            <Route path='/quotation-request/:id' element={<QuotationRequest />} />
            <Route path="*" element={<Navigate replace to="/" />} />
        </Route>
    )
);
