export const prequalifyValidate = (component: string, value: string, condition?: string) => {
    if(
        component === 'firstName' ||
        component === 'lastName' ||
        component === 'title' ||
        component === 'address' ||
        component === 'city' ||
        component === 'province'
    ) {
        if(component === 'title' && !value) return 'Select title';
        if(component === 'address' && !value) return 'Address is required';
        if(component === 'city' && !value) return 'City is required';
        if(component === 'province' && !value) return 'Province is required';
        if((component === 'firstName' || component === 'lastName') && value){
            const namePattern:RegExp = /^(([A-Za-z]+[\-\']?)*([A-Za-z]+)?\s?)+([A-Za-z]+[\-\']?)*([A-Za-z]+)?$/;
            if(!namePattern.test(value)) return 'Only alphabetic characters are allowed';
            return null;
        };
        if(component === 'firstName') return 'Full name is required';
        if(component === 'lastName') return 'Last name is required';
    };
    
    if(component === 'emailAddress'){
        if(!value) return 'Email address is required';
        if(condition === 'onBlur'){
            const emailPattern:RegExp =  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if(!emailPattern.test(value)) return 'Invalid email address entered';
        };
    };

    if(component === 'cellNumber'){
        if(!value) return 'Cell phone number is required';

        if(condition === 'onBlur'){
            const numberPattern:RegExp = /^(\+27|0)[6-8][0-9]{8}?$/;
            if(!numberPattern.test(value.replaceAll('-', ''))) return `Invalid cell phone number entered`;
        };
        // const numberPattern:RegExp = /[0-9]/;
        // if(!value) return 'Cell phone number is required';
        
        // if(value){
        //     if(!numberPattern.test(value)) return 'Cellphone number must be a number';
        // };

        // if(condition === 'onBlur'){
        //     const numberPattern:RegExp = /^(\+27|0)[6-8][0-9]{8}?$/;
        //     if(!numberPattern.test(value)) return `Invalid cell phone number entered`;
        //     if(value.length !== 10) return `Cell phone number must be 10 characters`;
        // };
    };

    if(component === 'idNumber'){
        if(!value) return 'ID number is required';
        const numberPattern:RegExp = /[0-9]/;
        if(!numberPattern.test(value)) return 'ID number must be a number';
        if(condition === 'onBlur'){
            const numberPattern:RegExp = /^(([0-9]{2})(0|1)([0-9])([0-3])([0-9]))([ ]?)(([0-9]{4})([ ]?)([0-1][8]([ ]?)[0-9]))?$/;
            if(!numberPattern.test(value)) return 'Invalid ID number entered';
            if(value.length !== 13) return 'ID number must be 13 digits';
        };
    };

    if(component === 'postalCode'){
        if(component === 'postalCode' && !value) return 'Postal code is required';
        const numberPattern:RegExp = /[0-9]/;
        if(!numberPattern.test(value)) return 'Postal code must be a number';
    };
    return null;
    
};