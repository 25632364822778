import { SocialsType } from "../components/types/socials.type";
import { IconsEnum } from "../enums/icon.enum";

export const Socials: SocialsType[] = [
    {
        name: "Facebook",
        url: 'https://www.facebook.com/AvisSouthAfrica',
        icon: IconsEnum.FACEBOOK
    },
    {
        name: "Twitter",
        url: 'https://twitter.com/AvisSouthAfrica',
        icon: IconsEnum.TWITTER
    },
    {
        name: 'Instagram',
        url: 'https://www.instagram.com/avissouthafrica',
        icon: IconsEnum.INSTAGRAM
    },
    {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/avissouthafrica',
        icon: IconsEnum.LINKEDIN
    }
]