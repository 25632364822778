import React from 'react'
import { ImagePreloader } from '../../shared/helpers/image-preloader';
import { OffersSection } from './components/offers-section';
import './styles.css'
import { colors } from '../../shared/colors';
import { btnStyle } from '../../shared/helpers/helper';
import { Link } from 'react-router-dom';

const imageUrls = [
  require('../../assets/images/home-banner.jpg'),
  require('../../assets/images/home1.jpg'),
  require('../../assets/images/home2.jpg'),
  require('../../assets/images/home3.jpg'),
  require('../../assets/images/home4.jpg'),
];

export const Homepage = (): React.JSX.Element => {
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  
  return (
    <ImagePreloader images={imageUrls}>
      <div className='homeBgImage'>
        <div className='homeBannerOverlay h-full'>
          <div className='flex flex-col justify-center mx-auto max-w-7xl p-6 lg:px-8' style={{minHeight: '60vh'}}>
            <p className='uppercase text-white text-lg md:text-4xl 2xl:text-5xl'>
              Welcome to the world of choice,
              <br />
              flexibility and hassle-free mobility
            </p>
            <span className="w-56 border-t mt-5" style={{borderColor: colors.primary}}></span>
            <p className='text-white text-secondary py-3'>Your car. Your budget. Your terms. <span className='font-semibold' style={{color: colors.primary}}>Our Hassle.</span></p>
          </div>
        </div>
      </div>
      <div className='mx-auto max-w-7xl p-6 lg:px-8'>
        <div className='grid grid-cols-1 md:grid-cols-2 mt-2 mb-10 md:mt-10 md:mb-20 gap-x-20'>
          <img src={require('../../assets/images/home1.jpg')} className='w-full h-fit object-contain' alt='img' />
          <div className='pt-10 md:pt-0'>
            <p className='border-b-2 border-opacity-30 border-red-500 text-gray-500 text-xl md:text-2xl pb-5 mb-8'>
              Financing your vehicle through balloon payments and the likes may seem appealing at first… until 
              you realise all the other costs that come with this option.
              <br />
              This is why we created Avis iLease as an ideal option for you!
            </p>
            <Link to={'about-ilease'} style={{...btnStyle as any, paddingLeft: 10}}>Learn more</Link>
          </div>
        </div>
        <OffersSection />
      </div>
    </ImagePreloader>
  );
};
