import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { colors } from '../../colors';
import { resolveUrl } from '../../helpers/helper';
import { footer } from './data';
import { IconResolver } from '../../helpers/icon-resolver';
import { Socials } from '../../data/data';

export const Footer = () => {
    
    const location = useLocation();
    const [currentPage, setCurrentPage] = React.useState<string>('');

    React.useEffect(() => {
        setCurrentPage(resolveUrl(location.pathname));
    },[location]);
    
    return (
        <div style={{backgroundColor: colors.primary}}>
            <div className='flex flex-col mx-auto max-w-7xl p-6 lg:px-8'>
                <div className='flex flex-col md:flex-row border-b border-opacity-30'>
                    <div className='md:p-10 w-full md:w-3/5'>
                        <h1 className='text-white text-lg font-semibold border-b w-fit pb-1 uppercase'>About us</h1>
                        <p className='mt-5 text-white text-sm'>
                            Avis iLease is the latest market-leading solution from Avis Fleet, 
                            bringing revolution to the passenger vehicle market. 
                            <br />
                            <br />
                            Avis Fleet is an innovator and leader in the outsourced fleet management industry, 
                            with a proud 40-year history in business excellence. Our customers can rest assured 
                            that they will receive the best care and service available. We try harder.
                        </p>
                    </div>
                    <div className='md:pl-16 py-10 md:w-2/5'>
                        <h1 className='text-white text-lg font-semibold border-b w-fit pb-1 uppercase'>Quick Links</h1>
                        <div className='grid grid-cols-2 mt-5 gap-2'>
                            {
                                footer.map((page, index: number) =>
                                    <div key={index} className='flex flex-row items-center gap-x-4 hover:scale-105'>
                                        {IconResolver(page.icon)}
                                        <Link 
                                            key={page.route}
                                            to={page.route}
                                            className={
                                                `
                                                text-sm leading-6 text-white py-1 hover:underline underline-offset-2
                                                ${currentPage === page.route ? 'underline' : ''}
                                                `
                                            }
                                            onClick={() => setCurrentPage(page.route)}
                                            >
                                            {page.name}
                                        </Link>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-row w-full h-10 gap-3 items-center py-1 mt-2'>
                    {
                        Socials.map((social, index: number) => 
                            <a href={social.url} target='_blank' rel="noreferrer" className='hover:scale-125' key={index}>
                                {IconResolver(social.icon)}
                            </a>
                        )
                    }
                </div>
            </div>
        </div>
    );
};
