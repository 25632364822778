import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { verifyEmail } from '../../lib/services/auth.service';
import { useAppDispatch } from '../../redux/store';
import { setUser } from '../../redux/features/userSlice';
import ReactLoading from 'react-loading';
import { colors } from '../../shared/colors';
import { Banner } from '../../shared/components/banner';
import { ImagePreloader } from '../../shared/helpers/image-preloader';

const imageUrls = [
    require('../../assets/images/login.jpg'),
    require('../../assets/images/oops.png'),
    require('../../assets/images/verified.png')
];

export const VerifyEmail = () => {
    
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const navigation = useNavigate();
    const [failed, setFailed] = React.useState<boolean>(false);
    const [success, setSuccess] = React.useState<boolean>(false);
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
        doVerify(id as string);
    });

    const doVerify = async (id: string) => {
        const user = await verifyEmail(id);
        if(user) {
            setSuccess(true);
            setTimeout(() => {
                dispatch(setUser({user: user[0], token: null}));
            }, 3000);
            return true;
        };
        setFailed(true);
        setTimeout(() => {
            navigation('/auth/login');
        }, 5000);
    };
    
    if(success){
        return(
            <>
                <Banner image={require('../../assets/images/login.jpg')} text="Account Verification" origin="Account Verification" />
                <div className='flex flex-col items-center justify-center text-gray-500 mx-auto max-w-7xl p-6 lg:px-8'>
                    <img src={require('../../assets/images/verified.png')} alt='success' className='md:w-80 md:h-80' />
                    <h1 className='text-2xl text-gray-600 mt-10'>Account verified successfully.</h1>
                </div>
            </>
        );
    };
    
    if(failed){
        return(
            <>
                <Banner image={require('../../assets/images/login.jpg')} text="Account Verification" origin="Account Verification" />
                <div className='flex flex-col items-center justify-center h-full w-full py-10'>
                    <img src={require('../../assets/images/oops.png')} alt='not-found' className='md:w-105 md:h-80' />
                    <h1 className='text-2xl text-gray-600 mt-10'>Failed to verify account</h1>
                </div>
            </>
        );
    };
        
    return (
        <ImagePreloader images={imageUrls}>
            <Banner image={require('../../assets/images/login.jpg')} text="Account Verification" origin="Account Verification" />
            <div className='flex flex-col justify-center items-center text-gray-500 mx-auto max-w-7xl py-10 p-6 lg:px-8'>
                <h1 className='text-xl text-gray-600'>Verifying your account</h1>
                <ReactLoading type={'bars'} color={colors.primary} height={100} width={100} />
                <h1 className='text-md text-gray-600 mt-10'>Please be patient while we verify your account..</h1>
            </div>
        </ImagePreloader>
    );
};
