import React from 'react'
import { Banner } from '../../../shared/components/banner'
import { ImagePreloader } from '../../../shared/helpers/image-preloader';

const imageUrls = [
    require('../../../assets/images/prequalify.jpg')
]

export const Outcome = (): React.JSX.Element => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    });

  return (
    <ImagePreloader images={imageUrls}>
        <Banner image={require('../../../assets/images/prequalify.jpg')} text="Quotation Request" origin="Quotation Request" />
        <div className='flex flex-col mx-auto max-w-7xl py-10 p-6 lg:px-8'>
            <div className='flex flex-row items-center gap-x-10'>
                <div className='flex flex-col text-gray-500 gap-y-5'>
                    <h1 className='text-xl'>Thank you for your quotation request.</h1>
                    <p>We’ll confirm pricing with the appropriate dealer in order to provide you with binding quotations.</p>
                    <p>
                        Please note, quotations will be sent to your email address. We will endeavour to provide the 
                        quotations within 24hours but due to the time that might be involved, to source the vehicle 
                        (and a formal dealer quotation), this process can take between 1 and 3 days.
                    </p>
                </div>
                <div className='hidden md:flex w-fit'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="173" height="173" viewBox="0 0 173 173">
                        <path id="prequalify-approved-icon" d="M72.548,103.264,48.82,79.535a6.581,6.581,0,0,0-9.307,0h0l-7.892,7.892a6.581,6.581,0,0,0,0,9.306L67.9,133.008a6.581,6.581,0,0,0,9.306,0l-.707-.707.707.707,64.177-64.177a6.58,6.58,0,0,0,0-9.306l-7.892-7.892a6.581,6.581,0,0,0-9.307,0ZM172,86.5A85.5,85.5,0,1,1,86.5,1,85.5,85.5,0,0,1,172,86.5Z" fill="none" stroke="#d4002a" stroke-width="2"/>
                    </svg>
                </div>
            </div>
        </div>
    </ImagePreloader>
  )
}
