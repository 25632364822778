import { client } from "../lib";

export const getUserByEmailAndPassword = async (email: string, password: string) => {
    return await client.fetch(
        '*[_type == "user" && emailAddress == $email && password == $password && verified == $verified]', 
        {email: email, password: password, verified: false}
    );
};

export const getUserByIdNumber = async (idNumber: string) => {
    return await client.fetch(
        '*[_type == "user" && idNumber == $idNumber && verified == $verified]', 
        {idNumber: idNumber, verified: true}
    );
};