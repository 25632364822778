import { PortableText } from '@portabletext/react'
import React from 'react'
import './style.css';
import { Dialog, DialogContent, DialogContentText, IconButton } from '@mui/material';
import { urlFor } from '../../../lib/lib';
import CloseIcon from '@mui/icons-material/Close';
import { bgImage } from '../../../shared/helpers/helper';

export const Faq = ({faq}: any) => {

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <>
      <div 
        style={bgImage(urlFor(faq?.image[0])?.url(), 200)} 
        onClick={() => setIsOpen(true)}
        className='cursor-pointer'
      >
        <div className='flex flex-row items-center h-full w-full p-10 redOverlay'>
          <h1 className='text-lg text-center font-semibold text-white'>{faq.title}</h1>
        </div>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div className='flex flex-row justify-between text-red-600 pt-3 px-6'>
          <h1 style={{fontSize: 18, maxWidth: '95%'}}>{faq.title.toUpperCase()}</h1>
          <IconButton
            edge="end"
            color="inherit"
            onClick={()=>setIsOpen(false)}
            sx={{ position: 'absolute', top: 5, right: 20 }}
            >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <DialogContentText className='faqLink'>
            <PortableText
              value={faq.description}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}
