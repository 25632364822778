import { AccountDetailsDto } from "../../shared/dtos/account-details.dto";
import { ContactUsDto } from "../../shared/dtos/contact-us.dto";

export const sendVerificationEmail = async (firstName: string, lastName: string, emailAddress: string, userId: string, origin: string) => {
    const params = new URLSearchParams();
    let result = null;
    params.append("name", firstName + ' '+ lastName);
    params.append("email", emailAddress);
    // params.append("verifyLink", `${process.env.REACT_APP_URL}/verify-email/${userId}`);
    params.append("verifyLink", `${origin}/verify-email/${userId}`);
    const url = `https://zeda.apigw-aw-eu.webmethods.io/gateway/AS400/1.0/verifyEmail?${params}`;
    await fetch(url, {
        method: 'POST',
        headers: {
            'x-Gateway-APIKey': 'e0e1e582-2897-47ae-9745-e940e8d8479b'
        },
        })
        .then(response => response.json())
        .then((res) => {
            // console.log(res)
            result = res})
        .catch((error) => console.error(error));
    return result
};

export const uploadDocument = async (form: AccountDetailsDto, document: any) => {
    var reader = new FileReader();
    reader.readAsDataURL(document.doc);
    return new Promise((resolve, reject) => {
        reader.onload = async () =>{
            const file = reader.result?.toString().replace('data:application/pdf;base64,', '');
            const url = 'https://zedadev.apigw-aw-eu.webmethods.io/gateway/AvisGraphSharepoint/1.0/createUploadDocument';
            const body =  {
                Name: document.doc.name,
                ContentNetworkPath: "",
                Path: "",
                Extension: ".pdf",
                Template: "iLease",
                IndexUser: form.firstName,
                Source: "ILease website",
                Fields: [
                    {
                        Key: "IdNumber",
                        Value: form.idNumber
                    },
                    {
                        Key: "EmailAddress",
                        Value: form.emailAddress
                    },
                    {
                        Key: "DocumentType",
                        Value: document.type
                    }
                ],
                Content: `${file}`
            }
            try {
                const res = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-Gateway-APIKey': '4f871673-3455-49fb-abfb-f3cfb2fbedca',
                    },
                    body: JSON.stringify(body)
                })
                // .then((res) => console.log(res))
                // .catch((err) => console.log(err, 'errrrr'));
                resolve(res);
            } catch (error) {
                resolve('did not upload ' +  document.doc.name)
            }
        };
        reader.onerror = () => {
            reject(reader.error);
        };
    });
};

export const sendClientInformtion = async (form: AccountDetailsDto) => {
    const params = new URLSearchParams();
    params.append("Title", form.title);
    params.append("FullName", form.firstName);
    params.append("LastName", form.lastName);
    params.append("IdNumber", form.idNumber);
    params.append("EmailAddress", form.emailAddress);
    params.append("CellNumber", form.cellNumber);
    params.append("Address1", form.address);
    params.append("Address2", form.apartment);
    params.append("City", form.city);
    params.append("Province", form.province);
    params.append("postalCode", form.postalCode);
    const url = `https://zeda.apigw-aw-eu.webmethods.io/gateway/PrivateLeasing_TempCustData/1.0/insertTempCustData?${params}`; 
    try {
        await fetch(url, {
            method: "POST",
            body: null
        });
        // .catch((err) => console.log(err, 'err'))
    } catch (error) {
        // console.log(error)
    }
};

// export const sendConfirmation = async (idNumber: string) => {
//     const params = new URLSearchParams();
//     let result: number = 500;
//     params.append("id", idNumber);
//     const url = `https://zedadev.apigw-aw-eu.webmethods.io/gateway/privateLeasing/1.0/sendAppEmail?${params}`;
//     await fetch(url, {
//         method: 'POST',
//         headers: {
//             'x-Gateway-APIKey': '2ad496ed-2b7d-4b05-af28-9c076fb74891'
//         },
//         })
//         .catch((error) => console.error(error));
//         return result
// };

export const sendResetLink = async (emailAddress: string, userId: string, origin: string) => {
    // await sendVerificationEmail('hee', 'wefwe', 'luyanda@developmenthub.co.za', 'dfver')
    // return 404;
    const params = new URLSearchParams();
    let result: number = 500;
    params.append("email", emailAddress);
    // params.append("link", `${process.env.REACT_APP_URL}/auth/reset-password/${userId}`);
    params.append("link", `${origin}/auth/reset-password/${userId}`);
    const url = `https://zeda.apigw-aw-eu.webmethods.io/gateway/privateLeasing/1.0/sendILeaseEmail?${params}`;
    await fetch(url, {
        method: 'POST',
        // headers: {
        //     'x-Gateway-APIKey': '2ad496ed-2b7d-4b05-af28-9c076fb74891'
        // },
        })
        .then((res) => {
            result = res.status
        })
        .catch((error) => console.error(error));
        return result
};

export const sendContactUs = async (form: ContactUsDto) => {
    const params = new URLSearchParams();
    let result = null;
    params.append("firstName", form.firstName);
    params.append("lastName", form.lastName);
    params.append("email", form.emailAddress);
    params.append("message", form.message);
    const url = `https://zeda.apigw-aw-eu.webmethods.io/gateway/privateLeasing/1.0/contactUsForm?${params}`;
    await fetch(url, {
        method: 'POST',
        // headers: {
        //     'Origin':'http://192.168.1.64:3000',
        // },
    })
    .then((res) => result = res)
    .catch((error) => console.error(error));
    return result
};

export const submitInsuranceQuestionnaire = async(questionnaire: any): Promise<any> => {
    let result = null;
    const params = new URLSearchParams();
    params.append("IdNumber", questionnaire.idNumber);
    params.append("Name", questionnaire.name);
    params.append("Question1", questionnaire.question1);
    params.append("Question2", questionnaire.question2);
    params.append("Question3", questionnaire.question3);
    params.append("Question4", questionnaire.question4);
    params.append("Question5", questionnaire.question5);
    const url: string = `https://zeda.apigw-aw-eu.webmethods.io/gateway/privateLeasing/1.0/insuranceQuestionnaire?${params}`;
    await fetch(url, {
        method: 'POST'
    })
    .then((res) => result = res)
    .catch((error) => console.log(error));
    return result;
};

export const submitQuotationRequest = async (form: any) => {
    let result = null;
    const params = new URLSearchParams();
    params.append("customerName", form.customerName);
    params.append("idNumber", form.idNumber);
    params.append("contactNumber", form.contactNumber);
    params.append("email", form.emailAddress);
    params.append("averageMonthlyKM", form.avgMonthlyKM);
    params.append("preferredContractPeriod", form.prefContractPeriod);
    params.append("requiredDeliveryDateArea", form.deliveryDateAndArea);
    params.append("specialRequest", form.specialRequest);
    params.append("comments", form.comments);
    params.append("VDS1_mmCode", form.mmCode);
    params.append("VDS1_vehicleMakeRange", form.make);
    params.append("VDS1_model", form.model);
    params.append("VDS1_optionalAccessories", form.optionalAccessories);
    params.append("VDS2_mmCode", form.mmCodeOpt);
    params.append("VDS2_vehicleMakeRange", form.makeOpt);
    params.append("VDS2_model", form.modelOpt);
    params.append("VDS2_optionalAccessories", form.optionalAccessoriesOpt);
    const url: string = `https://zeda.apigw-aw-eu.webmethods.io/gateway/privateLeasing/1.0/quoteRequestForm?${params}`
    await fetch(url, {
        method: 'POST'
    })
    .then((res) => result = res)
    .catch((error) => console.log('error', error));
    return result;
};