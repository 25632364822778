import { toast } from "react-toastify";
import { colors } from "../../../shared/colors";
import { AccountDetailsDto } from "../../../shared/dtos/account-details.dto";
import { UploadDocumentsDto } from "../../../shared/dtos/upload-documents.dto";
import { FileTypeEnum } from "../../../shared/enums/file-type.enum";
import { prequalifyValidate } from "./validate";

export const getColor = (file: any): string => file ? 'green' : colors.primary;
  
export const isFilesUploaded = (files: UploadDocumentsDto, consent?: boolean): boolean => {
    if (
        files.idDocument && 
        files.proofOfAddress && 
        files.licence && 
        files.payslip && 
        files.bankStatement 
        // && 
        // consent
    )
    return true;
    else return false;
};
  
export const isFormValid = (form: AccountDetailsDto, setValidator: any): boolean => {
  const title = prequalifyValidate('title', form.title, 'onBlur');
  const firstName = prequalifyValidate('firstName', form.firstName, 'onBlur');
  const lastName = prequalifyValidate('lastName', form.lastName, 'onBlur');
  const cellNumber = prequalifyValidate('cellNumber', form.cellNumber, 'onBlur');
  const emailAddress = prequalifyValidate('emailAddress', form.emailAddress, 'onBlur');
  const idNumber = prequalifyValidate('idNumber', form.idNumber, 'onBlur');
  const address = prequalifyValidate('address', form.address, 'onBlur');
  const apartment = prequalifyValidate('apartment', form.apartment, 'onBlur');
  const city = prequalifyValidate('city', form.city, 'onBlur');
  const province = prequalifyValidate('province', form.province, 'onBlur');
  const postalCode = prequalifyValidate('postalCode', form.postalCode, 'onBlur');
    
  setValidator({
    title: title,
    firstName: firstName,
    lastName: lastName,
    cellNumber: cellNumber,
    emailAddress: emailAddress,
    idNumber: idNumber,
    address: address,
    apartment: apartment,
    city: city,
    province: province,
    postalCode: postalCode
  });
  
  if(
    !title &&
    !firstName &&
    !lastName &&
    !cellNumber &&
    !emailAddress &&
    !idNumber &&
    !address &&
    !apartment &&
    !city &&
    !province &&
    !postalCode
  )return true
  else return false;
};

export const handleFileUpload = (file: any, fileType: FileTypeEnum, files: any, setFiles: any) => {
  if(file.length === 0) return;
  if(file[0].type !== 'application/pdf'){
    toast.warning('Please upload a .pdf type file.');
    return;
  };
  const sizeMB = (file[0].size / 1024) / 1024;
  if(sizeMB > 5){
    toast.warning('File size to large. Please upload file less than 5MB.');
    return;
  };
  switch (fileType) {
    case FileTypeEnum.ID_DOCUMENT :
      setFiles({...files, idDocument: {
        doc: file[0],
        type: FileTypeEnum.ID_DOCUMENT
      }});
      return;
    case FileTypeEnum.PROOF_OF_ADDRESS :
      setFiles({...files, proofOfAddress: {
        doc: file[0],
        type: FileTypeEnum.PROOF_OF_ADDRESS
      }});
      return;
    case FileTypeEnum.LICENCE :
      setFiles({...files, licence: {
          doc: file[0],
          type: FileTypeEnum.LICENCE
      }});
      return;
    case FileTypeEnum.PAYSLIP :
      setFiles({...files, payslip: {
        doc: file[0],
        type: FileTypeEnum.PAYSLIP
      }});
      return;
    case FileTypeEnum.BANK_STATEMENT :
      setFiles({...files, bankStatement: {
        doc: file[0],
        type: FileTypeEnum.BANK_STATEMENT
      }});
      return;
    default :
      return;
  };
};

export const fileIconStyle = (file: any) => (
  {
    color: getColor(file),
    height: 30, 
    width: '100%',
    marginBottom:10
  }
);