import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url'

export const client = createClient({
    projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
    // dataset: 'dev',
    dataset: 'production',
    apiVersion: '2023-07-17',
    useCdn: true,
    token: process.env.SANITY_TOKEN
});

const builder = imageUrlBuilder(client);
export const urlFor = (source: any) => builder.image(source);