import React from 'react'
import { urlFor } from '../../../lib/lib'
import { MoreInfo } from './more-info';
import { colors } from '../../../shared/colors';

export const VehicleCard = ({vehicle, handlePrequalify}: any) => {

  const [moreInfo, setMoreInfo] = React.useState<boolean>(false);
  const [choice, setChoice] = React.useState<any>(vehicle.options[0]);
  
  return (
    <div
      style={{cursor: 'pointer'}}
      onMouseEnter={() => {
        setMoreInfo(true);
        setChoice(vehicle.options[0]);
      }}
      onMouseLeave={() => setMoreInfo(false)}
    >
      <div className='flex flex-col h-60 w-full'>
        <div 
          className='text-white font-semibold rounded-full px-2' 
          style={{position: 'absolute', backgroundColor: moreInfo ? colors.primary : '#000' }}
        >R {vehicle.options[0].price}</div>
        <div className='flex flex-col w-full items-center h-2/3 border-b-2'>
          <img src={urlFor(vehicle.image[0]).url()} alt='car.jpg' className='h-full' />
        </div>
        <h1 className='text-sm font-semibold mt-2 text-gray-500'>{vehicle.make} {vehicle.range}</h1>
        <label className='text-sm text-gray-500'> {vehicle.model}</label>
        <label className='text-sm text-gray-500'>Price on <span className='text-red-500 font-semibold'>{vehicle.options[0].duration}</span></label>
      </div>
      {moreInfo && <MoreInfo vehicle={vehicle} choice={choice} setChoice={setChoice} handlePrequalify={handlePrequalify} />}
    </div>
  );
};
