import React from 'react'
import { CustomLoading } from '../components/custom-loading';

export const ImagePreloader = ({images, children}: any): React.JSX.Element => {

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  
  React.useEffect(() =>{
    const imagePromises = images.map((src: any) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = src;
      });
    });
  
    Promise.all(imagePromises)
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error('Error preloading images:', error);
        setIsLoading(false);
      });
  }, [images]);

  if(isLoading) return <CustomLoading />
  
  return (
    <>
      {children}
    </>
  );
};
