import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface CartState {
    vehicle: any | null;
    redirectUrl: string | null;
};

const initialState: CartState = {
    vehicle: null,
    redirectUrl: null
};

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        setCart: (state, action: PayloadAction<CartState>) => {
            // console.log("setCart")
            // state.user = null;
            state.vehicle = action.payload.vehicle;
            state.redirectUrl = action.payload.redirectUrl;
        },
        clearCart: (state) =>{
            state.vehicle = null;
            state.redirectUrl = null;
        },
    },
});

export const { setCart, clearCart } = cartSlice.actions;
export default cartSlice.reducer;