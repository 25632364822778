import React from 'react';
import { useAppDispatch } from '../../../redux/store';
import { setTodoQuestionnaire } from '../../../redux/features/generalSlice';
import { useNavigate } from 'react-router-dom';
import { btnStyle } from '../../helpers/helper';
import { Button } from '@mui/material';
import { colors } from '../../colors';
import { ListItem } from './list-item';

export const CallToAction = (): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const handlePrequalify = () => {
      dispatch(setTodoQuestionnaire(true));
      navigate('/insurance-questionnaire');
    };

    return (
        <div className='flex flex-col gap-x-4 border-t-2 pt-3 mt-3 gap-y-1 md:mb-10'>
            <div className='hidden md:flex flex-col'>
                <h1 className='font-bold mb-2' style={{color: colors.primary}}>Pre-Qualify</h1>
                <ListItem text='Create Profile' />
                <ListItem text='Fill Insurance Questionnaire' />
                <ListItem text='Upload FICA Documents' />
                <ListItem text='Finalise' />
                <Button
                    onClick={handlePrequalify}
                    style={{...btnStyle as any}}
                >
                    Pre-qualify
                </Button>
            </div>
            <div className='flex flex-col md:hidden'>
                <Button
                        onClick={handlePrequalify}
                        style={{...btnStyle as any, paddingInline: 0}}
                    >
                        Pre-qualify
                </Button>
            </div>
        </div>
    );
};
