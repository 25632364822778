import { colors } from '../../../shared/colors';
import { Banner } from '../../../shared/components/banner';
import React from 'react';

export const Outcome = () => {

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const origin: string = window.location.origin;
    setTimeout(() => {
      window.location.replace(origin + '/my-cars')
    }, 20000);
  });

  return (
    <>
    <Banner image={require('../../../assets/images/prequalify.jpg')} text="Pre-qualify" origin="Pre-qualify" />
      <div className='mx-auto max-w-7xl py-10 p-6 lg:px-8'>
        <div className='flex flex-row justify-between items-center py-5 text-gray-500 gap-x-10'>
          <h1 className='md:text-xl w-1/2 font-bold' style={{color: colors.primary}}>
            Thank you for your Pre-Approval request, we’ll email you the result shortly.
          </h1>
          <svg xmlns="http://www.w3.org/2000/svg" width="173" height="173" viewBox="0 0 173 173">
            <path id="prequalify-approved-icon" d="M72.548,103.264,48.82,79.535a6.581,6.581,0,0,0-9.307,0h0l-7.892,7.892a6.581,6.581,0,0,0,0,9.306L67.9,133.008a6.581,6.581,0,0,0,9.306,0l-.707-.707.707.707,64.177-64.177a6.58,6.58,0,0,0,0-9.306l-7.892-7.892a6.581,6.581,0,0,0-9.307,0ZM172,86.5A85.5,85.5,0,1,1,86.5,1,85.5,85.5,0,0,1,172,86.5Z" fill="none" stroke="#d4002a" stroke-width="2"/>
          </svg>
        </div>
      </div>
    </>
  )
}
