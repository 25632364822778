import { useState } from 'react'
import { Dialog, Popover } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Link, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { colors } from '../../colors'
import { logout } from '../../../redux/features/userSlice'
import LogoutIcon from '@mui/icons-material/Logout';
import React from 'react'
import { resolveUrl } from '../../helpers/helper'
import { header } from './data'

export default function Header() {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const user = useAppSelector((state) => state.user.user);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [inHeader, setInHeader] = useState(false)
  const [currentPage, setCurrentPage] = React.useState<string>('');
  
  React.useEffect(() => {
    setCurrentPage(resolveUrl(location.pathname));
    const loc = header.filter((header) => header.route === location.pathname);
    setInHeader(loc.length > 0 ? true : false)
  },[location]);

  return (
    <header style={{backgroundColor: colors.primary, position: 'fixed', left: 0, right: 0, zIndex: 100}}>
      <nav className="mx-auto flex max-w-7xl items-center justify-between px-6 lg:px-8" aria-label="Global">

        {/* Web */}
        <div className="hidden md:flex md:flex-1 md:mr-10">
          <Link
            to='/'
            className='hidden md:flex flex-row items-center'
          >
            <img src={require('../../../assets/images/logo.png')} alt='logo' style={{height: 70}} />
          </Link>
        </div>
        
        {/* MOBILE */}
        <div className="flex flex-1 md:hidden h-20">
          <Link
            to='/'
            className='flex flex-1 items-center'
          >
            <img src={require('../../../assets/images/logo.png')} alt='logo' style={{height: 70}} />
          </Link>
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-4 pt-6">
          {
            header.map((page) =>
              <Link 
                key={page.route}
                to={page.route}
                className={
                  `
                    text-sm font-semibold text-white px-5 py-2 hover:border-b-4
                    ${currentPage === page.route ? 'border-b-4 pb-6' : inHeader ? '' : 'pb-7'}
                  `
                }
                onClick={() => setCurrentPage(page.route)}
              >
                {page.name}
              </Link>
            )
          }
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-row lg:items-end lg:flex-1 lg:justify-end">
          {
            user
              ? <div className='flex flex-row items-center gap-x-2'>
                  <Link
                    to='/auth/login'
                    style={{color: colors.primary}}
                    className='text-sm font-semibold leading-6 text-white px-5 rounded-lg py-2 hover:bg-red-400 hover:bg-opacity-50'
                    onClick={() => dispatch(logout())}
                  >
                    <LogoutIcon style={{color: 'white', fontSize: 25}} />
                  </Link>
                </div>
              : <Link
                  to='/auth/login'
                  className="text-sm font-semibold border border-white px-5 py-2 hover:bg-red-500 hover:bg-opacity-50"
                  style={{color: '#fff'}}>
                  Login/Sign Up
                </Link>
          }
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel style={{backgroundColor: colors.primary}} className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          <div className="flex items-center justify-end">
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-white">
              <div className="space-y-2 py-6">
                <div className='flex flex-col'>
                  {
                    header.map((page) =>
                      <Link 
                        key={page.route}
                        to={page.route}
                        className={
                          `
                            text-sm font-semibold leading-6 text-white px-5 py-2 hover:border-b-2
                            ${currentPage === page.route ? 'border-b-2' : ''}
                          `
                        }
                        onClick={() => {
                          setCurrentPage(page.route);
                          setMobileMenuOpen(false);
                        }}
                      >
                        {page.name}
                      </Link>
                    )
                  }
                </div>
              </div>
              <div className="pt-1">
                {
                  user
                    ? <div className='flex flex-col items-center'>
                        <Link
                          to='/auth/login'
                          style={{color: colors.primary}}
                          className='-mx-3 mt-2 w-full block px-3 py-2.5 text-base font-semibold leading-7 text-white hover:border-b-2'
                          onClick={() => {
                            setMobileMenuOpen(false);
                            dispatch(logout());
                          }}
                        >
                          <span className='text-white'>
                            <LogoutIcon style={{color: 'white', fontSize: 20, marginRight: 10}} />Logout
                          </span>
                        </Link>
                      </div>
                    : <Link
                      to='/auth/login'
                        style={{color: colors.primary}}
                        className='-mx-3 w-full block px-3 py-2.5 text-base font-semibold leading-7 text-white hover:border-b-2'
                        onClick={() => {
                          setMobileMenuOpen(false);
                          dispatch(logout());
                        }}
                      >
                        <span className='text-white'>Login/Sign Up</span>
                      </Link>
                }
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
