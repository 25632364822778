import { TextField } from '@mui/material';
import { isError } from '../../../shared/helpers/helper';
import { prequalifyValidate } from '../helpers/validate';
import { ComponentEnum } from '../enums/component.enum';

export const CustomValidatedInput = ({label, type, component, value, form, setForm, validator, setValidator, disabled=false, required=true}: any) => {
  return (
    <TextField
      label={label}
      type={type}
      variant="outlined"
      required={required}
      disabled={disabled}
      value={value}
      error={isError(validator)}
      helperText={validator}
      onChange={(e) => handleInput(component, e.target.value, form, setForm, validator, setValidator)}
      onBlur={(e) => handleInput(component, e.target.value, form, setForm, validator, setValidator, 'onBlur')}
      fullWidth
    />
  );
};

const handleInput = (component: string, value: any, form: any, setForm:any, validator: any, setValidator: any, condition?: string): void => {
  switch (component) {
    case ComponentEnum.ID_NUMBER:
      setForm({...form, idNumber: value}) 
      setValidator({...validator, idNumber: prequalifyValidate(component, value, condition)});
      break;
    case ComponentEnum.FIRST_NAME:
      setForm({...form, firstName: value}) 
      setValidator({...validator, firstName: prequalifyValidate(component, value, condition)});
      break;
    case ComponentEnum.LAST_NAME:
      setForm({...form, lastName: value}) 
      setValidator({...validator, lastName: prequalifyValidate(component, value, condition)});
      break;
    case ComponentEnum.EMAIL_ADDRESS:
      setForm({...form, emailAddress: value}) 
      setValidator({...validator, emailAddress: prequalifyValidate(component, value, condition)});
      break;
    case ComponentEnum.CELL_NUMBER:
      setForm({...form, cellNumber: value}) 
      setValidator({...validator, cellNumber: prequalifyValidate(component, value, condition)});
      break;
    case ComponentEnum.ADDRESS:
      setForm({...form, address: value}) 
      setValidator({...validator, address: prequalifyValidate(component, value, condition)});
      break;
    case ComponentEnum.APARTMENT:
      setForm({...form, apartment: value}) 
      setValidator({...validator, apartment: prequalifyValidate(component, value, condition)});
      break;
    case ComponentEnum.CITY:
      setForm({...form, city: value}) 
      setValidator({...validator, city: prequalifyValidate(component, value, condition)});
      break;
    case ComponentEnum.POSTAL_CODE:
      setForm({...form, postalCode: value}) 
      setValidator({...validator, postalCode: prequalifyValidate(component, value, condition)});
      break;
    default:
      break;
  }
};
