import React from 'react'
import { colors } from '../../../shared/colors';

export const SquareBullet = ({ text }: any): React.JSX.Element => {
    return (
        <div className='flex flex-row gap-5 mt-4'>
            <div style={{backgroundColor: colors.primary, height:14, minWidth: 14, marginTop: 6}}></div>
            <p className='text-gray-500'>{text}</p>
        </div>
    );
};