import React from 'react'
import { Banner } from '../../shared/components/banner'
import { colors } from '../../shared/colors'
import { AccountDetailsDto } from '../../shared/dtos/account-details.dto';
import { UploadDocumentsDto } from '../../shared/dtos/upload-documents.dto';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { toast } from 'react-toastify';
import { FileTypeEnum } from '../../shared/enums/file-type.enum';
import { fileIconStyle, getColor, handleFileUpload, isFilesUploaded, isFormValid } from './helpers/helper';
import { sendClientInformtion, uploadDocument } from '../../lib/services/avis-api.service';
import { setToDoPrequalify } from '../../redux/features/generalSlice';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { btnStyle, formatNumber, isError } from '../../shared/helpers/helper';
import { prequalifyValidate } from './helpers/validate';
import { TitleEnum } from '../../shared/enums/title-enum';
import { CustomValidatedInput } from './components/custom-validated-input';
import { ComponentEnum } from './enums/component.enum';
import { ProvinceEnum } from '../../shared/enums/province.enum';
import { LoadingButton } from '@mui/lab';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { Outcome } from './components/outcome';
import { ImagePreloader } from '../../shared/helpers/image-preloader';
import  '../../shared/styles/styles.css';

const imageUrls = [
  require('../../assets/images/prequalify.jpg')
];

export const Prequalify = (): JSX.Element => {  

  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isDone, setIsDone] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<UploadDocumentsDto>({
    idDocument: '',
    proofOfAddress: '',
    licence: '',
    bankStatement: '',
    payslip: '',
  });

  const [form, setForm] = React.useState<AccountDetailsDto>({
    title: '',
    firstName: user.firstName,
    lastName: user.lastName,
    idNumber: user.idNumber,
    cellNumber: user.cellNumber,
    emailAddress: user.emailAddress,
    address: '',
    apartment: '',
    city: '',
    province: '',
    postalCode: '',
  });

  const [validator, setValidator] = React.useState<any>({
    title: '',
    firstName: '',
    lastName: '',
    idNumber: '',
    cellNumber: '',
    emailAddress: '',
    address: '',
    apartment: '',
    city: '',
    province: '',
    postalCode: '',
    consent: null
  });
        
  const handleSubmit = async () =>{
    if(!isFormValid(form, setValidator)) return;
    if(!isFilesUploaded(files)){
      toast.warning('Please upload all required documents.');
      return;
    };
    setLoading(true);
    toast.success('Please wait while we upload your documents. Do not refresh page.');
    try {
      await uploadDocument(form, files.idDocument);
      await uploadDocument(form, files.licence);
      await uploadDocument(form, files.payslip);
      await uploadDocument(form, files.bankStatement);
      await uploadDocument(form, files.proofOfAddress);
      await sendClientInformtion(form);
      // toast.success('Done.');
      // setLoading(false);
      dispatch(setToDoPrequalify(false));
      setIsDone(true);
    } catch (error) {
      console.log(error)            
    };
  };

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  if(isDone) return <Outcome />
  
  return (
    <ImagePreloader images={imageUrls}>
      <Banner image={require('../../assets/images/prequalify.jpg')} text="Pre-qualify" origin="Pre-qualify" />
      <div className='mx-auto max-w-7xl py-10 p-6 lg:px-8'>
        <h1 className='uppercase font-bold text-md mb-5' style={{color: colors.primary}}>Create customer profile and determine customer affordability</h1>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-5 mb-5'>
          
          {/* Title */}
          <FormControl required>
            <InputLabel>Title</InputLabel>
            <Select
              label="Title"
              sx={{height: 55}}
              variant="outlined"
              error={isError(validator.title)}
              onChange={(e) => {
                setForm({...form, title: e.target.value as string});
                setValidator({...validator, title: prequalifyValidate('title', e.target.value as string)});
              }}
              onBlur={(e) => setValidator({...validator, title: prequalifyValidate('title', e.target.value, 'onBlur')})}
              required
              fullWidth
            >
                {
                    Object.values(TitleEnum).map((value) => 
                        <MenuItem key={value} value={value}>{value}</MenuItem>
                    )
                }
            </Select>
            <FormHelperText style={{color: colors.primary}}>{validator.title}</FormHelperText>
          </FormControl>

          {/* ID Number */}
          <CustomValidatedInput
            label="ID Number"
            type="number"
            component={ComponentEnum.ID_NUMBER}
            value={form.idNumber}
            form={form}
            setForm={setForm}
            validator={validator.idNumber}
            setValidator={setValidator}
            disabled={true}
          />

          {/* Full Name */}
          <CustomValidatedInput
            label="Full Name"
            type="text"
            component={ComponentEnum.FIRST_NAME}
            value={form.firstName}
            form={form}
            setForm={setForm}
            validator={validator.firstName}
            setValidator={setValidator}
            disabled={true}
          />

          {/* Last Name */}
          <CustomValidatedInput
            label="Last Name"
            type="text"
            component={ComponentEnum.LAST_NAME}
            value={form.lastName}
            form={form}
            setForm={setForm}
            validator={validator.lastName}
            setValidator={setValidator}
            disabled={true}
          />
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mb-5'>
          {/* Email Address */}
          <CustomValidatedInput
            label="Email Address"
            type="text"
            component={ComponentEnum.EMAIL_ADDRESS}
            value={form.emailAddress}
            form={form}
            setForm={setForm}
            validator={validator.emailAddress}
            setValidator={setValidator}
            disabled={true}
          />

          {/* Cell Number */}
          <TextField
            label='Cell Number'
            type='text'
            variant="outlined"
            required
            value={form.cellNumber}
            error={isError(validator.cellNumber)}
            helperText={validator.cellNumber}
            onChange={(e: any) => setForm({...form, cellNumber: formatNumber(e.target.value, e.nativeEvent.inputType)})}
            onBlur={(e) =>  setValidator({...validator, cellNumber: prequalifyValidate('cellNumber', e.target.value, 'onBlur')})}
            fullWidth
          />
        </div>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-5 mb-5'>

          {/* Address */}
          <CustomValidatedInput
            label="Address"
            type="text"
            component={ComponentEnum.ADDRESS}
            value={form.address}
            form={form}
            setForm={setForm}
            validator={validator.address}
            setValidator={setValidator}
          />

          {/* Apartment */}
          <CustomValidatedInput
            label="Apartment"
            type="text"
            component={ComponentEnum.APARTMENT}
            value={form.apartment}
            form={form}
            setForm={setForm}
            validator={validator.apartment}
            setValidator={setValidator}
            required={false}
          />

          <div className='md:col-span-2'>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
              
              {/* City */}
              <CustomValidatedInput
                label="City"
                type="text"
                component={ComponentEnum.CITY}
                value={form.city}
                form={form}
                setForm={setForm}
                validator={validator.city}
                setValidator={setValidator}
              />
          
              {/* Province */}
              <FormControl required>
                <InputLabel>Province</InputLabel>
                <Select
                  label="Province"
                  sx={{height: 55}}
                  variant="outlined"
                  error={isError(validator.province)}
                  onChange={(e) => {
                    setForm({...form, province: e.target.value as string});
                    setValidator({...validator, province: prequalifyValidate('province', e.target.value as string)});
                  }}
                  onBlur={(e) => setValidator({...validator, province: prequalifyValidate('province', e.target.value, 'onBlur')})}
                  required
                  fullWidth
                >
                  {
                    Object.values(ProvinceEnum).map((value) => 
                      <MenuItem key={value} value={value}>{value}</MenuItem>
                    )
                  }
                </Select>
                <FormHelperText style={{color: colors.primary}}>{validator.province}</FormHelperText>
              </FormControl>
              
              {/* Postal Code */}
              <CustomValidatedInput
                label="Postal Code"
                type="number"
                component={ComponentEnum.POSTAL_CODE}
                value={form.postalCode}
                form={form}
                setForm={setForm}
                validator={validator.postalCode}
                setValidator={setValidator}
              />
            </div>
          </div>
        </div>
        <h1 className='uppercase font-bold text-md mb-5' style={{color: colors.primary}}>You are required to upload your fica documents</h1>
        
        {/* Fica Documents */}
        <div className='grid grid-cols-2 md:grid-cols-5 gap-2 mt-5'>

          {/* ID Document */}
          <label className="custom-file-upload" style={{borderColor: getColor(files.idDocument)}}>
            <AttachmentIcon style={fileIconStyle(files.idDocument)} />
            <p className="text">{files.idDocument ? files.idDocument?.doc?.name : 'ID Document'}</p>
            <input accept='.pdf' type="file" id="idDocument"
              onChange={(e) => handleFileUpload(e.target.files, FileTypeEnum.ID_DOCUMENT, files, setFiles)}
            />
          </label>

          {/* Proof Of Address */}
          <label className="custom-file-upload" style={{borderColor: getColor(files.proofOfAddress)}}>
            <AttachmentIcon style={fileIconStyle(files.proofOfAddress)} />
            <p className="text">{files.proofOfAddress ? files.proofOfAddress?.doc?.name : 'Proof Of Address'}</p>
            <input accept='.pdf' type="file" id="proofOfAddress"
              onChange={(e) => handleFileUpload(e.target.files, FileTypeEnum.PROOF_OF_ADDRESS, files, setFiles)}
            />
          </label>

          {/* Licence */}
          <label className="custom-file-upload" style={{borderColor: getColor(files.licence)}}>
            <AttachmentIcon style={fileIconStyle(files.licence)} />
            <p className="text">{files.licence ? files.licence?.doc?.name : 'Licence'}</p>
            <input accept='.pdf' type="file" id="licence"
              onChange={(e) => handleFileUpload(e.target.files, FileTypeEnum.LICENCE, files, setFiles)}
            />
          </label>

          {/* Payslip */}
          <label className="custom-file-upload" style={{borderColor: getColor(files.payslip)}}>
            <AttachmentIcon style={fileIconStyle(files.payslip)} />
            <p className="text">{files.payslip ? files.payslip?.doc?.name : 'Latest 3 month Payslip'}</p>
            <input accept='.pdf' type="file" id="payslip"
              onChange={(e) => handleFileUpload(e.target.files, FileTypeEnum.PAYSLIP, files, setFiles)}
            />
          </label>

          {/* Bank Statement */}
          <label className="custom-file-upload" style={{borderColor: getColor(files.bankStatement)}}>
            <AttachmentIcon style={fileIconStyle(files.bankStatement)} />
            <p className="text">{files.bankStatement ? files.bankStatement?.doc?.name : 'Latest 3 month Bank Statement'}</p>
            <input accept='.pdf' type="file" id="bankStatement"
              onChange={(e) => handleFileUpload(e.target.files, FileTypeEnum.BANK_STATEMENT, files, setFiles)}
            />
          </label>
        </div>

        <div className='flex flex-row justify-start mt-2'>
          <LoadingButton
            style={{...btnStyle as any, backgroundColor: loading ? 'gray' : colors.primary}}
            onClick={handleSubmit}
            loading={loading}
            loadingPosition="start"
            startIcon={<></>}
            disabled={loading}
            variant="contained"
            >
            <span>Submit</span>
          </LoadingButton>
        </div>
      </div>
    </ImagePreloader>
  );
};
