import React from 'react'
import { colors } from '../../shared/colors';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserByIdNumber } from '../../lib/services/user.service';
import ReactLoading from 'react-loading';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { btnStyle, isError } from '../../shared/helpers/helper';
import { validate } from './validators';
import { submitQuotationRequest } from '../../lib/services/avis-api.service';
import { toast } from 'react-toastify';
import { Outcome } from './components/outcome';

const initForm: any = {
    customerName: '',
    idNumber: '',
    contactNumber: '',
    emailAddress: '',
    avgMonthlyKM: null,
    prefContractPeriod: null,
    deliveryDateAndArea: null,
    specialRequest: null,
    date: null,
    time: null,
    // required form
    mmCode: null,
    make: null,
    model: null,
    optionalAccessories: null,
    // optional form
    mmCodeOpt: null,
    makeOpt: null,
    modelOpt: null,
    optionalAccessoriesOpt: null,
};

export const QuotationRequest = () => {
    
    const { id } = useParams();
    const navigate = useNavigate();
    const [user, setUser] = React.useState<any>();
    const [pageLoading, setPageLoading] = React.useState<boolean>(true);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isDone, setIsDone] = React.useState<boolean>(false);
    const [form, setForm] = React.useState<any>(initForm);
    const [validator, setValidator] = React.useState<any>({
        avgMonthlyKM: null,
        prefContractPeriod: null,
        deliveryDateAndArea: null,
        make: null,
        model: null,
        makeOpt: null,
        modelOpt: null,
    });
    const [shouldValidateOpts, setShouldValidateOpts] = React.useState<boolean>(false);

    React.useEffect(() => {
        getUser();
    }, [user]);
    
    const getUser = async () => {
        const user = await getUserByIdNumber(id as string);
        if(user.length === 0) {
            setUser(user);
            navigate('/');
            return;
        };
        setForm({...form,
            customerName: user[0].firstName + ' ' + user[0].lastName,
            idNumber: user[0].idNumber,
            contactNumber: user[0].cellNumber,
            emailAddress: user[0].emailAddress
        });
        setPageLoading(false);
    };

    const handleSubmit = async () => {
        const data = Object.assign({}, form);
        data.date = new Date().toISOString().substring(0, 10);
        data.time = new Date().toTimeString().substring(0, 8);
        if(!isFormValid(data, setValidator, shouldValidateOpts)) return;
        setLoading(true);
        submitQuotationRequest(data)
        .then((response) => {
            if(!response) {
                toast.error('Failed to submit quotation request');
                setLoading(false);
                return;
            };
            // toast.success('Quotation request submitted successfully!');
            setIsDone(true);
            // navigate('/vehicles');
        });

    };
  
    if(pageLoading) return (
      <div className="flex flex-row justify-center items-center h-full">
        <ReactLoading type={'bars'} color={colors.primary} height={100} width={100} />
      </div>
    );
  
    if(isDone) return <Outcome />

    return (
        <div className='flex flex-col mx-auto max-w-7xl py-10 p-6 lg:px-8 gap-y-10'>
            {/* CUSTOMER INFO */}
            <div className='flex flex-row font-bold px-3'>
                <h1 className='underline'>QUOTATION REQUEST</h1>
                <p className='mx-2'>-</p>
                <h1 className='underline'>iLease</h1>
            </div>
            <div className='flex flex-col w-full font-semibold gap-y-4 border-b-4 border-black pb-5 px-3'>
                <div className='flex flex-row'>
                    <h3 className='w-60'>Customer Name</h3>
                    <div className='w-96 border-b-2'>{form.customerName}</div>
                </div>
                <div className='flex flex-row'>
                    <h3 className='w-60'>ID Number</h3>
                    <div className='w-96 border-b-2'>{form.idNumber}</div>
                </div>
                <div className='flex flex-row'>
                    <h3 className='w-60'>Contact Number</h3>
                    <div className='w-96 border-b-2'>{form.contactNumber}</div>
                </div>
                <div className='flex flex-row'>
                    <h3 className='w-60'>E-mail</h3>
                    <div className='w-96 border-b-2'>{form.emailAddress}</div>
                </div>
            </div>
            {/* VALUE ADDS */}
            <div className='flex flex-col w-full gap-y-4 border-b-4 border-black pb-5 px-3'>
                <h2 className='font-semibold'>Compulsory Value-Added Products</h2>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-y-2'>
                    <p>AA Corporate</p>
                    <p>Telematics</p>
                    <p>iLease Insurance</p>
                    <p>Driver management</p>
                    <p>License Renewal</p>
                    <p>Traffic Fines Management per infringement</p>
                </div>
            </div>
            {/* VEHICLE DETAILS */}
            <div className='flex flex-col w-full gap-y-4 border-b-4 border-black pb-5 px-3'>
                <h1 className='font-bold underline'>Vehicle Details (2 selections per quote request)</h1>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-5 gap-x-20'>
                    {/* Mandatory Fields */}
                    <div className='flex flex-col gap-y-5'>
                        {/* AVIS QUOTE */}
                        {/* <div className='flex flex-col md:flex-row md:gap-x-4'>
                            <label className='md:w-40 text-gray-400 mb-10 md:mb-0'><span className='md:flex'>Avis Quote #</span> (office use)</label>
                            <div className='w-full border-b border-gray-400'></div>
                        </div> */}
                        {/* M & M */}
                        <div className='flex flex-col md:flex-row md:gap-x-4 md:items-end'>
                            <label className='md:w-40 mb-3 md:mb-0'>*M & M Code</label>
                            <TextField
                                type="text"
                                variant="standard"
                                fullWidth
                                value={form.mmCode}
                                onChange={(e) => {
                                    setForm({...form, mmCode: e.target.value}) 
                                }}
                            />
                        </div>
                        {/* MAKE */}
                        <div className='flex flex-col md:flex-row md:gap-x-4 md:items-end'>
                            <label className='md:w-40 mb-3 md:mb-0'>VehicleMake/Range:</label>
                            <TextField
                                placeholder='e.g., Volkswagen Polo'
                                type="text"
                                variant="standard"
                                required
                                fullWidth
                                value={form.make}
                                error={isError(validator.make)}
                                helperText={validator.make}
                                onChange={(e) => {
                                    setForm({...form, make: e.target.value}) 
                                }}
                                onBlur={(e) => setValidator({...validator, make: validate(e.target.value, 'make')})}
                            />
                        </div>
                        {/* MODEL */}
                        <div className='flex flex-col md:flex-row md:gap-x-4 md:items-end'>
                            <label className='md:w-40 mb-3 md:mb-0'>Model:</label>
                            <TextField
                                placeholder='e.g., 1.4 Comfortline'
                                type="text"
                                variant="standard"
                                required
                                fullWidth
                                value={form.model}
                                error={isError(validator.model)}
                                helperText={validator.model}
                                onChange={(e) => {
                                    setForm({...form, model: e.target.value}) 
                                }}
                                onBlur={(e) => setValidator({...validator, model: validate(e.target.value, 'model')})}
                            />
                        </div>
                        {/* OPTIONAL ACCESSORIES */}
                        <div className='flex flex-col md:flex-row md:gap-x-4'>
                            <label className='md:w-40 mb-3 md:mb-0'>Optional Accessories</label>
                            <TextField
                                type="text"
                                variant="standard"
                                required
                                fullWidth
                                multiline
                                value={form.optionalAccessories}
                                onChange={(e) => {
                                    setForm({...form, optionalAccessories: e.target.value}) 
                                }}
                            />
                        </div>
                    </div>
                    {/* Optional Fields */}
                    <div className='flex flex-col gap-y-5'>
                        {/* AVIS QUOTE */}
                        {/* <div className='flex flex-col md:flex-row md:gap-x-4'>
                            <label className='md:w-40 text-gray-400 mb-10 md:mb-0'><span className='md:flex'>Avis Quote #</span> (office use)</label>
                            <div className='w-full border-b border-gray-400'></div>
                        </div> */}
                        {/* M & M */}
                        <div className='flex flex-col md:flex-row md:gap-x-4 md:items-end'>
                            <label className='md:w-40 mb-3 md:mb-0'>*M & M Code</label>
                            <TextField
                                type="text"
                                variant="standard"
                                required
                                fullWidth
                                value={form.mmCodeOpt}
                                onChange={(e) => {
                                    setForm({...form, mmCodeOpt: e.target.value}) 
                                }}
                            />
                        </div>
                        {/* MAKE */}
                        <div className='flex flex-col md:flex-row md:gap-x-4 md:items-end'>
                            <label className='md:w-40 mb-3 md:mb-0'>VehicleMake/Range:</label>
                            <TextField
                                type="text"
                                variant="standard"
                                required
                                fullWidth
                                value={form.makeOpt}
                                error={shouldValidateOpts ? isError(validator.makeOpt) : false}
                                helperText={shouldValidateOpts ? validator.makeOpt : null}
                                onChange={(e) => {
                                    setShouldValidateOpts(e.target.value ? true : false);
                                    setForm({...form, makeOpt: e.target.value}) 
                                }}
                                onBlur={(e) => {
                                    if(shouldValidateOpts){
                                        setValidator({...validator, makeOpt: validate(e.target.value, 'make')})
                                    };
                                }}
                            />
                        </div>
                        {/* MODEL */}
                        <div className='flex flex-col md:flex-row md:gap-x-4 md:items-end'>
                            <label className='md:w-40 mb-3 md:mb-0'>Model:</label>
                            <TextField
                                type="text"
                                variant="standard"
                                required
                                fullWidth
                                value={form.modelOpt}
                                error={shouldValidateOpts ? isError(validator.modelOpt) : false}
                                helperText={shouldValidateOpts ? validator.modelOpt : null}
                                onChange={(e) => {
                                    setShouldValidateOpts(e.target.value ? true : false);
                                    setForm({...form, modelOpt: e.target.value}) 
                                }}
                                onBlur={(e) => {
                                    if(shouldValidateOpts){
                                        setValidator({...validator, modelOpt: validate(e.target.value, 'model')})
                                    };
                                }}
                            />
                        </div>
                        {/* OPTIONAL ACCESSORIES */}
                        <div className='flex flex-col md:flex-row md:gap-x-4'>
                            <label className='md:w-40 mb-3 md:mb-0'>Optional Accessories</label>
                            <TextField
                                type="text"
                                variant="standard"
                                required
                                fullWidth
                                multiline
                                value={form.optionalAccessoriesOpt}
                                onChange={(e) => {
                                    setForm({...form, optionalAccessoriesOpt: e.target.value}) 
                                }}
                            />
                        </div>
                    </div>
                    {/* AVERAGE MONTHLY KMS */}
                    <div className='flex flex-col md:flex-row md:gap-x-4 md:items-end'>
                        <label className='md:w-80 mb-3 md:mb-0'>Average monthly kilometers required</label>
                        <TextField
                            type="number"
                            variant="standard"
                            required
                            className='w-full md:w-72'
                            value={form.avgMonthlyKM}
                            error={isError(validator.avgMonthlyKM)}
                            helperText={validator.avgMonthlyKM}
                            onChange={(e) => {
                                setForm({...form, avgMonthlyKM: e.target.value}) 
                            }}
                            onBlur={(e) => setValidator({...validator, avgMonthlyKM: validate(e.target.value, 'avgMonthlyKM')})}
                        />
                    </div>
                    {/* PREFERRED MONTHLY PERIOD */}
                    <div className='flex flex-col md:flex-row md:gap-x-4 md:items-end'>
                        <label className='md:w-72 mb-3 md:mb-0'>Preferred contract period</label>
                        <TextField
                            type="number"
                            variant="standard"
                            required
                            fullWidth
                            value={form.prefContractPeriod}
                            error={isError(validator.prefContractPeriod)}
                            helperText={validator.prefContractPeriod}
                            onChange={(e) => {
                                setForm({...form, prefContractPeriod: e.target.value}) 
                            }}
                            onBlur={(e) => setValidator({...validator, prefContractPeriod: validate(e.target.value, 'prefContractPeriod')})}
                        />
                    </div>
                    {/* REQUIRED DELIVERY DATE & AREA */}
                    <div className='flex flex-col md:flex-row col-span-full md:gap-x-4'>
                        <label className='md:w-80 mb-3 md:mb-0'>Required Delivery Date & Area</label>
                        <TextField
                            type="text"
                            variant="standard"
                            required
                            fullWidth
                            multiline
                            value={form.deliveryDateAndArea}
                            error={isError(validator.deliveryDateAndArea)}
                            helperText={validator.deliveryDateAndArea}
                            onChange={(e) => {
                                setForm({...form, deliveryDateAndArea: e.target.value}) 
                            }}
                            onBlur={(e) => setValidator({...validator, deliveryDateAndArea: validate(e.target.value, 'deliveryDateAndArea')})}
                        />
                    </div>
                    {/* SPECIAL REQUEST */}
                    <div className='flex flex-col md:flex-row col-span-full md:gap-x-4'>
                        <label className='md:w-80 mb-3 md:mb-0'>Special Request</label>
                        <TextField
                            type="text"
                            variant="standard"
                            required
                            fullWidth
                            multiline
                            value={form.specialRequest}
                            // error={isError(validator.specialRequest)}
                            // helperText={validator.specialRequest}
                            onChange={(e) => {
                                setForm({...form, specialRequest: e.target.value}) 
                            }}
                        />
                    </div>
                </div>
            </div>
            {/* COMMENTS */}
            <div className='flex flex-col w-full gap-y-4 border-b-4 border-black pb-5 px-3'>
                <div className='flex flex-col md:flex-row col-span-full md:gap-x-4'>
                    <label className='md:w-28 mb-3 md:mb-0'>Comments</label>
                    <TextField
                        type="text"
                        variant="standard"
                        required
                        fullWidth
                        multiline
                        value={form.comments}
                        // error={isError(validator.comments)}
                        // helperText={validator.comments}
                        onChange={(e) => {
                            setForm({...form, comments: e.target.value}) 
                        }}
                    />
                </div>
            </div>
            {/* DATE TIME SECTION */}
            {/* <div className='grid grid-cols-2'>
                <div className='flex flex-row gap-x-4 items-end'>
                    <FormLabel>Date</FormLabel>
                    <DatePicker
                        onChange={(date: any) => {
                            setForm({...form, date: date})
                        }}
                        maxDate={new Date()}
                        className='w-fit'
                        slotProps={{
                            textField: {
                                InputLabelProps: {
                                    shrink: true,
                                },
                                variant: 'standard',
                            },
                        }}
                    />
                </div>
                <div className='flex flex-row gap-x-4 items-end'>
                    <FormLabel>Time</FormLabel>
                    <TimeField
                        variant='standard'
                        value={form.time}
                        onChange={(time) => setForm({...form, time: time})}
                        format="HH:mm"
                    />
                </div>
            </div> */}
            {/* BOTTOM SECTION */}
            <div className='italic font-semibold'>
                * M & M Code = Mead & McGrouther Code (applicable to customers who source their own vehicle)
            </div>
            <div>
                <LoadingButton
                    style={{...btnStyle as any, backgroundColor: loading ? 'gray' : colors.primary}}
                    onClick={handleSubmit}
                    loading={loading}
                    loadingPosition="start"
                    disabled={loading}
                    variant="contained"
                >
                    <span>Submit</span>
                </LoadingButton>
            </div>
        </div>
    );
};

const isFormValid = (form: any, setValidate: any, shouldValidateOpts: boolean) => {
    const avgMonthlyKM = validate(form.avgMonthlyKM, 'avgMonthlyKM');
    const prefContractPeriod = validate(form.prefContractPeriod, 'prefContractPeriod');
    const deliveryDateAndArea = validate(form.deliveryDateAndArea, 'deliveryDateAndArea');
    const make = validate(form.make, 'make');
    const model = validate(form.model, 'model');
    let makeOpt = null;
    let modelOpt = null;
    if(shouldValidateOpts){
        makeOpt = validate(form.makeOpt, 'make');
        modelOpt = validate(form.modelOpt, 'model');
    };
    setValidate({
        avgMonthlyKM: avgMonthlyKM,
        prefContractPeriod: prefContractPeriod,
        deliveryDateAndArea: deliveryDateAndArea,
        make: make,
        model: model,
        makeOpt: makeOpt,
        modelOpt: modelOpt
    });
    if(
        !avgMonthlyKM  &&
        !prefContractPeriod &&
        !deliveryDateAndArea &&
        !make &&
        !model &&
        !makeOpt &&
        !modelOpt
    )
    return true
    else return false;
};