import React from 'react'
import { useAppSelector } from '../redux/store';
import { RouterProvider } from 'react-router-dom';
import { noauthRouter } from './noauth-navigation';
import { authRouter } from './auth-navigation';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const Navigator = (): React.JSX.Element => {
  const user = useAppSelector((state) => state.user.user);
    return(
      <>
        <RouterProvider router={user ? authRouter : noauthRouter} />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
      />
      </>
    );
}
