import { CreateUserDto } from "../../shared/dtos/create-user.dto";
import { LoginDto } from "../../shared/dtos/login.dto";
import { client } from "../lib";
import { sendResetLink, sendVerificationEmail } from "./avis-api.service";
import { getUserByEmailAndPassword } from "./user.service";

export const login = async (payload: LoginDto) => {
  return await client.fetch(
    '*[_type == "user" && emailAddress match $email && password == $password && verified == $verified]', 
    {email: payload.emailAddress.toLowerCase(), password: payload.password, verified: true}
  );
};


const userExists = async (email: string, idNumber: string) => {
  const user = await client.fetch(
    '*[_type == "user" && emailAddress match $email && idNumber == $idNumber]', 
    {email: email, idNumber: idNumber}
  );
  if(user.length > 0) 
    return true;
  else 
    return false;
};

export const createUser = async (user: CreateUserDto, origin: string) => {
  const exists = await userExists(user.emailAddress, user.idNumber);
  if(exists) return exists;
  let result: any = null;
  const mutations = {
    mutations: [
      {
        create: {
          _type: 'user',
          firstName: user.firstName,
          lastName: user.lastName,
          cellNumber: user.cellNumber,
          emailAddress: user.emailAddress,
          idNumber: user.idNumber,
          password: user.password,
          verified: false
        },
      },
    ],
  };

  await fetch(process.env.REACT_APP_SANITY_URL as string, {
    method: 'post',
    headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SANITY_TOKEN}`
    },
      body: JSON.stringify(mutations)
    })
    .then(response => response.json())
    .then(async () => {
      result = await getUserByEmailAndPassword(user.emailAddress, user.password)
      if(result.length > 0)
        sendVerificationEmail(result[0].firstName, result[0].lastName, result[0].emailAddress, result[0]._id, origin)
    })
    .catch(error => console.log(error))
  return result;
};

export const verifyEmail = async (id: string) => {
  let user = await client.fetch('*[_type == "user" && _id == $id && verified == $verified]', {id: id, verified: false});
  if(user.length === 0){
    return null;
  };
  const mutation = {
    mutations: [
      {
        patch: {
          id: id,
          set: {
            verified: true
          }
        }
      },
    ]
  };
  await fetch(process.env.REACT_APP_SANITY_URL as string, {
    method: 'post',
    headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SANITY_TOKEN}`
    },
      body: JSON.stringify(mutation)
    })
    .then(response => response.json())
    .then(() => user[0].verified = true)
    .catch(error => console.log(error))
    return user;
};

export const forgotPassword = async (emailAddress: string, origin: string) => {
  let user = await client.fetch('*[_type == "user" && emailAddress match $emailAddress]', {emailAddress});
  let result: number = 500;
  if(user.length === 0){
    return null;
  };
  await sendResetLink(user[0].emailAddress, user[0]._id, origin)
  .then((res) => result = res);
  return result;
};

export const resetPassword = async (id: string, password: string) => {
  let user = await client.fetch('*[_type == "user" && _id == $id]', {id: id});
  let result: boolean = false;
  if(user.length === 0){
    return false;
  };
  const mutation = {
    mutations: [
      {
        patch: {
          id: id,
          set: {
            password: password
          }
        }
      },
    ]
  };
  await fetch(process.env.REACT_APP_SANITY_URL as string, {
    method: 'post',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_SANITY_TOKEN}`
    },
      body: JSON.stringify(mutation)
    })
    // .then(response => response.json())
    .then(() => result = true)
    .catch(error => console.log(error))
    return result;
};