import React from 'react'
import ReactLoading from 'react-loading'
import { colors } from '../colors'
import { useAppSelector } from '../../redux/store'
import { useNavigate } from 'react-router-dom'

export const Authentication = () => {

    const toDoQuestionnaire = useAppSelector((state) => state.general.toDoQuestionnaire);
    const toDoPrequalify = useAppSelector((state) => state.general.toDoPrequalify);
    const navigate = useNavigate();

    React.useEffect(() => {
        resolveNavigation();
    }, [toDoQuestionnaire, toDoPrequalify]);

    const resolveNavigation = () => {
        if(toDoQuestionnaire) navigate('/insurance-questionnaire');
        else if(toDoPrequalify) navigate('/prequalify');
        else navigate('/my-car');
    };

    return (
        <div className="flex flex-row justify-center items-center h-full">
            <ReactLoading type={'bars'} color={colors.primary} height={100} width={100} />
        </div>
    );
};
