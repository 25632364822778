export enum ProvinceEnum {
    Eastern_Cape = 'Eastern Cape',
    Western_Cape = 'Western Cape',
    Northern_Cape = 'Northern Cape',
    Gauteng = 'Gauteng',
    North_West = 'North West',
    Free_State = 'Free State',
    KwaZulu_Natal = 'Kwa Zulu Natal',
    Mpumalanga = 'Mpumalanga',
    Limpopo = 'Limpopo',
}
