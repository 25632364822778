import React from 'react'
import ReactLoading from 'react-loading';
import { colors } from '../colors';

export const CustomLoading = () => {
    return (
        <div className="flex flex-row justify-center items-center h-full">
            <ReactLoading type={'bars'} color={colors.primary} height={100} width={100} />
        </div>
    );
};
